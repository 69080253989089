import { useEffect, useState } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import styles from './Event.module.scss';
import imsiEvent from '../../assets/imsi/imsiEvent.png';
import { useQuery } from 'react-query';
import axiosClient from '../../libs/axiosClient';
import { useNavigate } from 'react-router-dom';
const categoryList = ['전체', '진행중인 이벤트', '종료된 이벤트'];
interface IEventType {
  b_edate: string;
  list_image: string;
  b_sdate: string;
  b_title: string;
  b_uid: number;
}
function Event() {
  const navigate = useNavigate();
  const [selectedEventCategory, setSelectedEventCategory] = useState(0);
  const { data: eventList } = useQuery<IEventType[]>(
    'event',
    () => axiosClient.get('/data/event').then((res) => res.data.data),
    { staleTime: 60000 * 60 * 24 },
  );
  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.header}>
        <div>
          <span>EVENT</span>
          <span>온닥의 다양한 이벤트를 만나보세요</span>
        </div>
      </div>
      <ul className={styles.eventCategory}>
        {categoryList.map((category, idx) => (
          <li
            key={category}
            onClick={() => setSelectedEventCategory(idx)}
            className={idx == selectedEventCategory ? styles.hit : ''}
          >
            {category}
          </li>
        ))}
      </ul>
      <ul className={styles.eventList}>
        {eventList &&
          eventList
            .filter((event) => {
              let date = new Date().getTime();
              let startDate = new Date(event.b_sdate).getTime();
              let endDate = new Date(event.b_edate).getTime();
              if (date < startDate) return false;
              if (selectedEventCategory == 0) return true;
              else if (selectedEventCategory == 1) {
                if (startDate < date && date < endDate) return true;
                else return false;
              } else if (selectedEventCategory == 2) {
                if (endDate < date) return true;
                else return false;
              }
            })
            .map((event) => {
              let isProgressing = false;
              let date = new Date().getTime();
              let startDate = new Date(event.b_sdate).getTime();
              let endDate = new Date(event.b_edate).getTime();
              if (startDate < date && date < endDate) isProgressing = true;
              else isProgressing = false;
              return (
                <li
                  key={event.b_uid}
                  onClick={() => navigate(`/event/${event.b_uid}`)}
                >
                  <div className={styles.thumbnailWrap}>
                    <img src={event.list_image} alt="eventThumb" />
                  </div>
                  <div className={styles.eventInfoWrap}>
                    <div className={styles.writeInfo}>
                      <span>{event.b_sdate.replace(/-/gi, '.')}</span>
                    </div>
                    <div
                      className={`${styles.isProgressing} ${
                        !isProgressing ? styles.isOff : ''
                      }`}
                    >
                      {isProgressing ? '진행중' : '종료'}
                    </div>
                    <div className={styles.title}>{event.b_title}</div>
                  </div>
                </li>
              );
            })}
      </ul>
      <Footer />
    </div>
  );
}
export default Event;
