import { useEffect, useRef, useState } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import styles from './NonMyOrder.module.scss';
import axiosClient from '../../libs/axiosClient';
import { IMyPageInfoType } from '../MyPage/MyPage';
import { IOrderDataType } from '../OrderComplete/OrderComplete';
import prevNavi from '../../assets/prevNavi.png';
import load from '../../assets/loading.png';
import rightArrow from '../../assets/rightArrow.png';
import discountListMark from '../../assets/discountMark.png';
import OrderFrame from '../../components/OrderFrame/OrderFrame';
import { useLocation, useNavigate } from 'react-router-dom';
import { isDesktop, isMobile } from 'react-device-detect';
import { getPayMethodLabel } from '../../common/getPaymethod';
import { WEEKDAY } from '../Questionnaire/Questionnaire';

function NonMyOrder() {
  const existPayMethod: string | string[] = [];
  const nameRegex = /^[ㄱ-ㅎ가-힣a-zA-Z ]+$/;
  const navigate = useNavigate();
  const location = useLocation();
  const orderDetailRef = useRef<any>();
  const [orderInfoData, setOrderInfoData] = useState<any[]>([]);
  const [orderId, setOrderId] = useState('');
  const [orderName, setOrderName] = useState('');
  const [orderTel, setOrderTel] = useState('');
  const [noOrder, setNoOrder] = useState(false);
  const [orderData, setOrderData] = useState<IOrderDataType[]>();
  const [productIdList, setProductIdList] = useState<number[]>([]);
  const [reservationDate, setReservationDate] = useState('');
  const [okBtn, setOkBtn] = useState(false);

  useEffect(() => {
    const orderState = location.state;
    if (orderState) {
      setOrderId(orderState?.orderId);
      setOrderName(orderState?.orderName);
      setOrderTel(orderState?.orderTel);
      onSubmit(
        orderState?.orderId,
        orderState?.orderName,
        orderState?.orderTel,
      );
    }
  }, []);

  useEffect(() => {
    if (orderId && orderName && orderTel) setOkBtn(true);
    else setOkBtn(false);
  }, [orderId, orderName, orderTel]);

  useEffect(() => {
    if (!orderData) return;
    let list: number[] = [];
    orderData[0].products?.map((product) => {
      if (!reservationDate && product.is_reservation == 'Y')
        setReservationDate(product.reservation_at!);
      if (!list.includes(product.product_id)) list.push(product.product_id);
    });
    setProductIdList(list);

    // if (orderDetailRef.current)
    //   window.scrollTo(0, orderDetailRef.current.offsetTop);

    // 이전 페이지에서 가져온 state 값 초기화
    // navigate('/non-myorder');
  }, [orderData]);

  const getMyOrder = (id: string, name: string, tel: string) => {
    axiosClient
      .get(`/data/order/non-user?order_id=${id}&name=${name}&phone=${tel}`)
      .then((res) => {
        setOrderData(res.data.data);
        setOrderInfoData(res.data.data[1]);
        setNoOrder(false);
      })
      .catch((err) => {
        setOrderData(undefined);
        setOrderInfoData([]);
        if ((err.response.data.detail = 'Order not found')) setNoOrder(true);
      });
  };

  function handleOrderCancel() {
    let confirm = window.confirm('주문건을 취소하시겠습니까?');
    if (!confirm) return;
    axiosClient
      .get(`/func/order/order_cancel?order_id=${orderId}`)
      .then((res) => {
        // window.location.reload();
        getMyOrder(orderId, orderName, orderTel);
      })
      .catch((err) => alert('에러가 발생했습니다. 고객센터로 문의 바랍니다.'));
  }
  function deliveryCheck() {
    if (!orderData) return;
    window.open(
      `/deliveryDetail/${orderData[0].products[0].send_company}/${orderData[0].products[0].send_number}`,
      '',
      'width=500,height=700',
    );
  }
  function copyAddress() {
    navigator.clipboard
      .writeText('서울 강남구 테헤란로64길 8 지하1층')
      .then((res) => alert('주소가 복사되었습니다.'));
  }

  const onSubmit = (oid?: string, oname?: string, otel?: string) => {
    const id = oid ? oid : orderId;
    const name = oname ? oname : orderName;
    const tel = otel ? otel : orderTel;

    if (!id || !name || !tel)
      return alert(
        '주문번호, 주문자명, 주문자 연락처를 다시 한 번 확인해주세요.',
      );
    if (id.length !== 17)
      return alert(`주문번호는 '-' 포함 17자리로 작성해 주세요.`);

    axiosClient
      .get(`/data/order/non-user?order_id=${id}&name=${name}&phone=${tel}`)
      .then((res) => {
        setOrderData(res.data.data);
        setOrderInfoData(res.data.data[1]);
        setNoOrder(false);
      })
      .catch((err) => {
        setOrderData(undefined);
        setOrderInfoData([]);
        if ((err.response.data.detail = 'Order not found')) setNoOrder(true);
      });
  };

  return (
    <>
      <Header />
      <div className={`${styles.container} ${isMobile ? styles.isMobile : ''}`}>
        <div
          className={`${styles.mainWrap} ${noOrder ? styles.noMainWrap : ''}`}
        >
          <div className={styles.headerWrap}>
            {isDesktop ? (
              <span>
                비회원
                <br /> 주문 조회
              </span>
            ) : (
              <div>
                <img
                  onClick={() => {
                    navigate(-1);
                  }}
                  src={prevNavi}
                  alt="prev"
                />
                <span>비회원 주문 조회</span>
                <span></span>
              </div>
            )}
          </div>
          <div className={styles.nonMemList}>
            <div className={styles.inputListWrap}>
              <div className={styles.inputWrap}>
                <span>주문번호</span>
                <input
                  type="text"
                  value={orderId}
                  maxLength={17}
                  onChange={(e) => {
                    // let value = e.currentTarget.value;
                    // console.log(value.slice(0, value.length - 1));
                    // if (isNaN(Number(e.currentTarget.value)))
                    // setOrderId(value.slice(0, value.length - 1));
                    setOrderId(e.currentTarget.value);
                  }}
                  onBlur={() => setOrderId((prev) => prev.trim())}
                  placeholder="00000-00000-00000"
                />
              </div>
              <div className={styles.inputWrap}>
                <span>주문자명</span>
                <input
                  type="text"
                  value={orderName}
                  onChange={(e) => {
                    const inputValue = e.currentTarget.value;
                    const filteredValue = inputValue.replace(
                      /[^ㄱ-ㅎ가-힣a-zA-Z]/g,
                      '',
                    );
                    setOrderName(filteredValue);
                  }}
                  onBlur={() => setOrderName((prev) => prev.trim())}
                  placeholder="이름"
                />
              </div>
              <div className={styles.inputWrap}>
                <span>주문자 연락처</span>
                <input
                  type="text"
                  value={orderTel}
                  maxLength={11}
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    if (/^\d*$/.test(value)) setOrderTel(value);
                  }}
                  onBlur={() => setOrderTel((prev) => prev.trim())}
                  placeholder="01000000000"
                />
              </div>
            </div>
            <button
              className={`${styles.nonBtn} ${okBtn ? styles.nonOkBtn : ''}`}
              onClick={() => {
                if (!okBtn) return;
                onSubmit();
              }}
            >
              조회하기
            </button>
            {noOrder && (
              <div className={styles.noDataWrap}>
                <img src={load} alt="loading" />
                <div className={styles.textWrap}>
                  <span>조회된 주문 데이터가 없습니다.</span>
                  <span>
                    주문번호, 주문자명, 주문자 연락처를 다시 한 번 확인해주세요.
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        {orderData && (
          <div className={styles.orderWrap} ref={orderDetailRef}>
            <div className={styles.header}>
              <span>주문상세</span>
              {isMobile && <div></div>}
            </div>
            <div className={styles.orderInfoWrap}>
              <div className={styles.orderHeader}>
                <span>
                  {orderData &&
                    orderData[0].order_date &&
                    orderData[0].order_date
                      ?.substring(0, 10)
                      .replace(/-/gi, '.')}{' '}
                  주문
                </span>
                <span>주문번호 : {orderData && orderData[0].order_id}</span>
              </div>
              {orderData &&
                productIdList.map((id, idx) => {
                  let optionList: string[] = [];
                  let count = 0;
                  let productImage = '';
                  let productName = '';
                  let orderSendNumber = '';
                  let sendCompany = '';
                  orderData[0].products
                    .filter((product) => product.product_id == id)
                    .map((product) => {
                      optionList.push(product.option);
                      count += product.count;
                      productImage = product.image;
                      productName = product.title;
                      orderSendNumber = product.send_number || '미등록';
                      sendCompany = product.send_company || '';
                    });
                  return (
                    <div className={styles.orderProductInfoWrap} key={id}>
                      <div className={styles.sectionHeader}>
                        <span>주문상품정보</span>
                        {(orderData[0].pay_status == '배송대기' ||
                          orderData[0].pay_status == '결제완료') && (
                          <div
                            className={styles.orderCancelBtn}
                            onClick={handleOrderCancel}
                          >
                            주문취소
                          </div>
                        )}
                        {!(
                          orderData[0].pay_status == '결제대기' ||
                          orderData[0].pay_status == '배송대기' ||
                          orderData[0].pay_status == '결제완료'
                        ) &&
                          orderData[0].products[0].send_number && (
                            <div
                              className={styles.deliveryCheck}
                              onClick={deliveryCheck}
                            >
                              배송조회
                            </div>
                          )}
                        <div
                          className={styles.status}
                          style={{
                            borderColor:
                              orderData[0].pay_status == '배송대기'
                                ? '#F6F7F9'
                                : orderData[0].pay_status == '배송준비'
                                ? '#00BE35'
                                : orderData[0].pay_status == '배송중'
                                ? '#B7E7BD'
                                : orderData[0].pay_status == '배송완료'
                                ? '#121842'
                                : orderData[0].pay_status == '주문취소'
                                ? '#FF3D3D'
                                : orderData[0].pay_status == '결제대기'
                                ? '#3259E6'
                                : orderData[0].pay_status == '결제완료'
                                ? '#131C31'
                                : '',
                            color:
                              orderData[0].pay_status == '배송대기'
                                ? '#999999'
                                : orderData[0].pay_status == '배송준비'
                                ? '#00BE35'
                                : orderData[0].pay_status == '배송중'
                                ? '#00BE35'
                                : orderData[0].pay_status == '배송완료'
                                ? '#141736'
                                : orderData[0].pay_status == '주문취소'
                                ? '#FF3D3D'
                                : orderData[0].pay_status == '결제대기'
                                ? '#fff'
                                : orderData[0].pay_status == '결제완료'
                                ? '#fff'
                                : '',
                            backgroundColor:
                              orderData[0].pay_status == '배송대기'
                                ? '#F6F7F9'
                                : orderData[0].pay_status == '결제대기'
                                ? '#3259E6'
                                : orderData[0].pay_status == '결제완료'
                                ? '#131C31'
                                : 'white',
                          }}
                        >
                          {orderData[0].pay_status}
                        </div>
                      </div>
                      <div className={styles.productWrap}>
                        <div className={styles.thumbnailWrap}>
                          <img src={productImage} alt="thumbnail" />
                        </div>
                        <div className={styles.right}>
                          <span className={styles.productName}>
                            {productName}
                          </span>
                          <div className={styles.option}>
                            <span>옵션</span>
                            <ul>
                              {optionList.map((option) => (
                                <li key={option}>{option}</li>
                              ))}
                            </ul>
                          </div>
                          <div className={styles.count}>
                            <span>수량</span>
                            <span>{count}개</span>
                          </div>
                          <div className={styles.deliveryNumber}>
                            <span>운송장번호</span>
                            <span>
                              {orderSendNumber}
                              {sendCompany && <> ({sendCompany})</>}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            {/* <div className={styles.refundWrap}>
        <div className={styles.refundHeader}>교환/반품/환불 사유</div>
        <p>제품 하자로 인한 환불</p>
      </div> */}
            {reservationDate && (
              <div className={styles.reservationInfoWrap}>
                <div className={styles.reservationHeader}>방문 예약 정보</div>
                <div className={styles.reservationInfo}>
                  <div>
                    <span>예약병원</span>
                    <span>
                      마디로 한의원 선릉점{' '}
                      <div
                        className={styles.mapBtn}
                        onClick={() => {
                          window.open(
                            'https://map.naver.com/v5/search/%EB%A7%88%EB%94%94%EB%A1%9C%ED%95%9C%EC%9D%98%EC%9B%90/place/1036931386?c=15,0,0,0,dh&placePath=%3Fentry%253Dbmp',
                            '_blank',
                          );
                        }}
                      >
                        <span>지도보기</span>
                        <img src={rightArrow} alt="화살표" />
                      </div>
                    </span>
                  </div>
                  <div>
                    <span>병원주소</span>
                    <span>
                      서울 강남구 테헤란로64길 8 지하1층{' '}
                      <div className={styles.copyBtn} onClick={copyAddress}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <path
                            d="M11.9583 4.66675V11.0834H3.79163V1.16675H8.74996M11.9583 4.66675H8.74996V1.16675M11.9583 4.66675L8.74996 1.16675"
                            stroke="#FF5656"
                            strokeLinecap="square"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M2.04163 5.8335V12.8335H8.16663"
                            stroke="#FF5656"
                            strokeLinecap="square"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5.54163 5.8335H6.70829M5.54163 8.16683H9.04163"
                            stroke="#FF5656"
                            strokeLinecap="square"
                          />
                        </svg>
                        <span>복사</span>
                      </div>
                    </span>
                  </div>
                  <div>
                    <span>예약날짜</span>
                    <span>{`${reservationDate
                      .slice(0, 10)
                      .replace(/-/gi, '.')}(${
                      WEEKDAY[new Date(reservationDate).getDay()]
                    }) / ${reservationDate.slice(11, 16)}`}</span>
                  </div>
                </div>
              </div>
            )}
            {orderData &&
              orderData[0] &&
              orderData[0].pickup == 'Y' &&
              !reservationDate && (
                <div className={styles.reservationInfoWrap}>
                  <div className={styles.reservationHeader}>방문 수령 정보</div>
                  <div className={styles.reservationInfo}>
                    <div>
                      <span>수령병원</span>
                      <span>
                        마디로 한의원 선릉점{' '}
                        <div
                          className={styles.mapBtn}
                          onClick={() => {
                            window.open(
                              'https://map.naver.com/v5/search/%EB%A7%88%EB%94%94%EB%A1%9C%ED%95%9C%EC%9D%98%EC%9B%90/place/1036931386?c=15,0,0,0,dh&placePath=%3Fentry%253Dbmp',
                              '_blank',
                            );
                          }}
                        >
                          <span>지도보기</span>
                          <img src={rightArrow} alt="화살표" />
                        </div>
                      </span>
                    </div>
                    <div>
                      <span>병원주소</span>
                      <span>
                        서울 강남구 테헤란로64길 8 지하1층{' '}
                        <div className={styles.copyBtn} onClick={copyAddress}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <path
                              d="M11.9583 4.66675V11.0834H3.79163V1.16675H8.74996M11.9583 4.66675H8.74996V1.16675M11.9583 4.66675L8.74996 1.16675"
                              stroke="#FF5656"
                              strokeLinecap="square"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M2.04163 5.8335V12.8335H8.16663"
                              stroke="#FF5656"
                              strokeLinecap="square"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M5.54163 5.8335H6.70829M5.54163 8.16683H9.04163"
                              stroke="#FF5656"
                              strokeLinecap="square"
                            />
                          </svg>
                          <span>복사</span>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              )}
            <div className={styles.addressInfoWrap}>
              <div className={styles.addressHeader}>배송지 정보</div>
              <div className={styles.addressInfo}>
                <div>
                  <span>수령인</span>
                  <span>{orderData && orderData[0].receive_name}</span>
                </div>
                <div>
                  <span>휴대폰</span>
                  <span>{orderData && orderData[0].receive_phone}</span>
                </div>
                <div>
                  <span>주소지</span>
                  <span>{orderData && orderData[0].receive_address}</span>
                </div>
                <div>
                  <span>배송메모</span>
                  <span>{orderData && orderData[0].request_memo}</span>
                </div>
              </div>
            </div>
            <div className={styles.payInfoWrap}>
              <div className={styles.payHeader}>결제정보</div>
              <div className={styles.priceCalculation}>
                <div>
                  <span>주문 금액</span>
                  <span>
                    {orderData && orderData[0].price?.toLocaleString()}원
                  </span>
                </div>
                <div>
                  <span>배송비</span>
                  <span>
                    {orderData && orderData[0].delivery_price?.toLocaleString()}
                    원
                  </span>
                </div>
                <div>
                  <span>할인금액</span>
                  <span>
                    -
                    {orderData &&
                      (
                        orderData[0].use_coupon +
                        orderData[0].use_point +
                        orderData[0].use_promotion
                      )?.toLocaleString()}
                    원
                  </span>
                </div>
                <ul className={styles.discountInfoList}>
                  {orderData && orderData[0].use_coupon > 0 && (
                    <li>
                      <div>
                        <img src={discountListMark} alt="discountListMark" />
                        <span>쿠폰 사용</span>
                      </div>
                      <span>
                        -{orderData[0].use_coupon?.toLocaleString()}원
                      </span>
                    </li>
                  )}
                  {orderData && orderData[0].use_promotion > 0 && (
                    <li>
                      <div>
                        <img src={discountListMark} alt="discountListMark" />
                        <span>프로모션코드 사용</span>
                      </div>
                      <span>
                        -{orderData[0].use_promotion?.toLocaleString()}원
                      </span>
                    </li>
                  )}{' '}
                  {orderData && orderData[0].use_point > 0 && (
                    <li>
                      <div>
                        <img src={discountListMark} alt="discountListMark" />
                        <span>포인트 사용</span>
                      </div>
                      <span>-{orderData[0].use_point?.toLocaleString()}원</span>
                    </li>
                  )}
                </ul>
              </div>
              <div className={styles.totalPrice}>
                <div>
                  <span>최종 결제 금액</span>
                  <span>
                    {orderData && orderData[0].total_price?.toLocaleString()}원
                  </span>
                </div>
                <span>
                  최종 결제 금액은 결제 금액에서 할인/환불 금액을 뺀 금액입니다.
                </span>
              </div>
            </div>
            {/* <div className={styles.refundPriceWrap}>
        <div className={styles.refundPriceHeader}>환불안내</div>
        <div className={styles.refundCalculation}>
          <div>
            <span>주문 금액</span>
            <span>184,000원</span>
          </div>
          <ul className={styles.notRefundList}>
            <li>
              <span>반품 배송비</span>
              <span>10,000원</span>
            </li>
          </ul>
        </div>
        <div className={styles.totalRefundPrice}>
          <span>최종 환불 금액</span>
          <span>179,000원</span>
        </div>
      </div> */}
            <div className={styles.payMethodWrap}>
              <div className={styles.payMethodHeader}>
                <span>결제 수단</span>
              </div>
              {/* {orderData && orderData[0].card_info?.card_name && (
          <span>
            신용카드 ({orderData && orderData[0].card_info?.card_name}/
            {orderData &&
              (orderData[0].card_info?.card_quota == '00'
                ? '일시불'
                : `${orderData[0].card_info?.card_quota}개월할부`)}
            )
          </span>
        )}
        {orderData &&
          !orderData[0].card_info?.card_name &&
          orderData[0].card_info?.card_number && (
            <span>신용카드 ({orderData[0].card_info?.card_number})</span>
          )}
        {orderData &&
          orderData[0].order_paymethod !==
            ('virtual' || 'online' || 'card') && <span>{paymethodLabel}</span>} */}
              {orderData && orderData[0].bank_account ? (
                <div className={styles.payMethodListWrap}>
                  {orderInfoData &&
                    orderInfoData.map((info, idx) =>
                      info.paymethod === 'virtual' ? (
                        <div key={idx} className={styles.paymethodFrame}>
                          <div className={styles.labelWrap}>가상계좌</div>
                          <div className={styles.contentWrap}>
                            <div className={styles.leftWrap}>
                              <span>
                                {info.vbank && `[${info.vbank}]${info.vbankno}`}
                              </span>
                              <span>
                                {info.vbank_date &&
                                  `${info.vbank_date
                                    ?.substring(0, 10)
                                    .replace(/-/gi, '.')}까지`}
                              </span>
                            </div>
                            <div className={styles.rightWrap}>
                              <span>{`${Number(
                                info.price,
                              ).toLocaleString()}원`}</span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className={styles.paymethodFrame} key={idx}>
                          <div className={styles.labelWrap}>
                            {getPayMethodLabel(info.paymethod)}
                          </div>
                        </div>
                      ),
                    )}
                </div>
              ) : (
                orderData &&
                !orderData[0].bank_account && (
                  <div
                    // className={`${styles.payMethodListWrap} ${
                    //   !getVirtualPadding() ? styles.noPadding : ''
                    // }`}
                    className={styles.payMethodListWrap}
                  >
                    {orderInfoData &&
                      orderInfoData.map((info, idx) => {
                        if (info.paymethod === 'virtual') {
                          return (
                            <div key={idx} className={styles.paymethodFrame}>
                              <div className={styles.labelWrap}>가상계좌</div>
                              <div className={styles.contentWrap}>
                                <div className={styles.leftWrap}>
                                  <span>
                                    {info.vbank &&
                                      `[${info.vbank}]${info.vbankno}`}
                                  </span>
                                  <span>
                                    {info.vbank_date &&
                                      `${info.vbank_date
                                        ?.substring(0, 10)
                                        .replace(/-/gi, '.')}까지`}
                                  </span>
                                </div>
                                <div className={styles.rightWrap}>
                                  <span>{`${Number(
                                    info.price,
                                  ).toLocaleString()}원`}</span>
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          const payMethodLabel = getPayMethodLabel(
                            info.paymethod,
                          );
                          const isExist =
                            existPayMethod.includes(payMethodLabel);
                          if (!isExist) {
                            existPayMethod.push(payMethodLabel);
                            return (
                              <div key={idx} className={styles.paymethodFrame}>
                                <div className={styles.labelWrap}>
                                  {payMethodLabel}
                                </div>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        }
                      })}
                  </div>
                )
              )}
            </div>
            <div className={styles.refundGuide}>
              <span>모든 환불건은 최초 결제 수단으로 환불됩니다.</span>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default NonMyOrder;
