import { loginCheck } from '../auth/auth';

export function loginConfirm() {
  if (!loginCheck()) {
    let login = window.confirm(
      '로그인 후 이용 가능합니다. 로그인 하시겠습니까?',
    );
    if (login) {
      window.location.href = `/login?rurl=${
        window.location.pathname + window.location.search
      }`;
    } else {
      return false;
    }
  } else return true;
}

export function loginConfirmRurl(url) {
  if (loginCheck()) return true;

  let login = window.confirm('로그인 후 이용 가능합니다. 로그인 하시겠습니까?');
  if (login) window.location.href = `/login?rurl=${url}`;
  else return false;
  // if (!loginCheck()) {
  //   let login = window.confirm(
  //     '로그인 후 이용 가능합니다. 로그인 하시겠습니까?',
  //   );
  //   if (login) {
  //     window.location.href = `/login?rurl=${url}`;
  //   } else {
  //     return false;
  //   }
  // } else return true;
}

export function getRedirectUrl() {
  return window.location.pathname + window.location.search;
}
