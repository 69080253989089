import styles from './PrescriptionLoginModal.module.scss';
import box from '../../assets/question/box.png';
import { useNavigate } from 'react-router-dom';
import { isDesktop } from 'react-device-detect';
import { useEffect } from 'react';

function PrescriptionLoginModal({ closer, onContinueBuy }: any) {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <>
      <div
        className={`${styles.modalContainer} ${
          !isDesktop ? styles.isMobile : ''
        }`}
      >
        <div className={styles.modalWrap}>
          <div className={styles.textWrap}>
            <span>
              처방의약품은
              <br /> 로그인 후 구매 가능합니다.
            </span>
            <span> 로그인 하시겠습니까?</span>
          </div>
          <div className={styles.btnWrap}>
            <div
              onClick={() => {
                closer(false);
              }}
            >
              아니오
            </div>
            <div
              onClick={() => {
                window.location.href = `/login?rurl=${
                  window.location.pathname + window.location.search
                }`;
              }}
            >
              예
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrescriptionLoginModal;
