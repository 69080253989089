import styles from './SearchModal.module.scss';
import logoWhite from '../../assets/logoWhite.png';
import searchGray from '../../assets/searchGray.png';
import { useEffect } from 'react';
interface IPropsType {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
function SearchModal(props: IPropsType) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <img src={logoWhite} alt="logoWhite" className={styles.logo} />
        <form>
          <input type="text" placeholder="검색어를 입력해주세요." />
          <img src={searchGray} alt="searchGray" />
        </form>
        <span className={styles.closeBtn} onClick={() => props.setOpen(false)}>
          닫기
        </span>
      </div>
      <div className={styles.searchRecordWrap}></div>
    </div>
  );
}
export default SearchModal;
