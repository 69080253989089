import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ScrollTop from './components/ScrollTop/ScrollTop';
import CompanyInfo from './pages/CompanyInfo/CompanyInfo';
import Event from './pages/Event/Event';
import Faq from './pages/Faq/Faq';
import Home from './pages/Home/Home';
import MyOrder from './pages/MyOrder/MyOrder';
import MyPage from './pages/MyPage/MyPage';
import Notice from './pages/Notice/Notice';
import Order from './pages/Order/Order';
import OrderContinue from './pages/OrderContinue/OrderContinue';
import OrderDetail from './pages/OrderDetail/OrderDetail';
import ProductDetail from './pages/ProductDetail/ProductDetail';
import ProductDetail2 from './pages/ProductDetail/ProductDetail';
import ProductList from './pages/ProductList/ProductList';
import Review from './pages/Review/Review';
import { isDesktop } from 'react-device-detect';
import Basket from './pages/Basket/Basket';
import Pick from './pages/Pick/Pick';
import Terms from './pages/Terms/Terms';
import Guide from './pages/Guide/Guide';
import UnauthorizedEmailCollection from './pages/UnauthorizedEmailCollection/UnauthorizedEmailCollection';
import Privacy from './pages/Privacy/Privacy';
import Login from './pages/Login/Login';
import Point from './pages/Point/Point';
import Coupon from './pages/Coupon/Coupon';
import MyReview from './pages/MyReview/MyReview';
import OrderComplete from './pages/OrderComplete/OrderComplete';
import Join from './pages/Join/Join';
import axios from 'axios';
import Cookies from 'universal-cookie';
import JoinSuccess from './pages/JoinSuccess/JoinSuccess';
import Kakao from './auth/Kakao';
import Navigation from './components/Navigation/Navigation';
import OrderConfirm from './pages/OrderConfirm/OrderConfirm';
import HomeMobile from './mobilePages/HomeMobile/HomeMobile';
import UpdateProfile from './pages/UpdateProfile/UpdateProfile';
import MyCard from './pages/MyCard/MyCard';
import FindAccount from './pages/FindAccount/FindAccount';
import DeliveryDetail from './pages/DeliveryDetail/DeliveryDetail';
import Questionnaire from './pages/Questionnaire/Questionnaire';
import InAppGuide from './pages/InAppGuide/InAppGuide';
import GetGuideURL from './pages/GetGuideURL/GetGuideURL';
import Popup from './components/Popup/Popup';
import PageChangeAnimation from './components/PageChangeAnimation/PageChangeAnimation';
import EventDetail from './pages/EventDetail/EventDetail';
import Admin from './pages/Admin/Admin';
import SelectTreatmentMethod from './pages/SelectTreatmentMethod/SelectTreatmentMethod';
// import Challenge from './pages/Challenge/Challenge';
// import MyChallenge from './pages/MyChallenge/MyChallenge';
import MyWeight from './pages/MyWeight/MyWeight';
// import ChallengeDetail from './pages/ChallengeDetail/ChallengeDetail';
import WeightChart from './pages/WeightChart/WeightChart';
import WeightList from './pages/WeightList/WeightList';
import WeightDate from './pages/WeightDate/WeightDate';
import WeightDateChart from './pages/WeightDateChart/WeightDateChart';
import WeightPoint from './pages/WeightPoint/WeightPoint';
import Referral from './pages/Referral/Referral';
import ReferralPoint from './pages/ReferralPoint/ReferralPoint';
import ProgramList from './pages/ProgramList/ProgramList';
import HospitalList from './pages/HospitalList/HospitalList';
import { ITokenInfoType, loginCheck, logout } from './auth/auth';
import jwt_decode from 'jwt-decode';
import ChannelService from './ChannelService';
import axiosClient from './libs/axiosClient';
import OrderAddress from './pages/OrderAddress/OrderAddress';
import PayModal from './components/PayModal/PayModal';
import NonMyOrder from './pages/NonMyOrder/NonMyOrder';
import ChannelAlert from './components/ChannelAlert/ChannelAlert';

function App() {
  let memberId = '';
  let memberHash = '';
  const [combineOrderId, setCombineOrderId] = useState('');
  const [payModalOpen, setPayModalOpen] = useState(true);
  const [inappModalOpen, setInappModalOpen] = useState(false);
  const [id, setId] = useState('');
  const [channelAlert, setChannelAlert] = useState(false);

  useEffect(() => {
    const isFirstVisit = sessionStorage.getItem('isFirstVisit');
    if (
      !isFirstVisit &&
      navigator.userAgent.match(
        /inapp|NAVER|KAKAOTALK|Snapchat|Line|WirtschaftsWoche|Thunderbird|Instagram|everytimeApp|WhatsApp|Electron|wadiz|AliApp|zumapp|iPhone(.*)Whale|Android(.*)Whale|kakaostory|band|twitter|DaumApps|DaumDevice\/mobile|FB_IAB|FB4A|FBAN|FBIOS|FBSS|SamsungBrowser\/[^1]/i,
      )
    )
      setInappModalOpen(true);
  }, []);
  useEffect(() => {
    const userAgentLower = navigator.userAgent.toLowerCase();
    const mobileAppUserAgent = userAgentLower.match(/payple-pay-app/i);

    if (isDesktop) document.body.classList.add('pc');
    if (mobileAppUserAgent) document.documentElement.classList.add('mobile');

    if (mobileAppUserAgent) {
      localStorage.setItem('mobileApp', mobileAppUserAgent.toString());
      document.body.style.userSelect = 'none';
      return () => {
        document.body.style.userSelect = 'auto';
      };
    } else localStorage.removeItem('mobileApp');
  }, []);

  const getHash = async () => {
    await axiosClient
      .get('/data/channeltalk/hash', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        memberHash = res.data;
        getChannel();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getChannel = () => {
    ChannelService.boot({
      pluginKey: '3c7f74d3-0628-4b52-bcb2-0cfb99ca0751',
      memberId: memberId,
      memberHash: memberHash,
    });
    ChannelService.onShowMessenger(function onShow() {
      setChannelAlert(true);
    });
  };

  ChannelService.onHideMessenger(function onHide() {
    setChannelAlert(false);
  });

  useEffect(() => {
    ChannelService.loadScript();
    if (loginCheck()) {
      axiosClient.get('/data/combined_order').then((res) => {
        if (res.data.order_id) {
          setCombineOrderId(res.data.order_id);
          setPayModalOpen(true);
        }
      });

      const token = localStorage.getItem('token');
      if (token == null || token == 'null') return;
      const decodedUserInfo: ITokenInfoType = jwt_decode(token!);
      memberId = decodedUserInfo.id.toString();
      getHash();
    } else getChannel();
  }, []);

  return (
    <div>
      <BrowserRouter>
        {/* {inappModalOpen && <InAppModal setOpen={setInappModalOpen} />} */}
        {/* {window.location.pathname === '/' && <Popup />} */}
        {combineOrderId && payModalOpen && (
          <PayModal closer={setPayModalOpen} orderId={combineOrderId} />
        )}
        <PageChangeAnimation />
        <ScrollTop />
        <Routes>
          <Route path="/" element={isDesktop ? <Home /> : <HomeMobile />} />
          <Route path="/login" element={<Login />} />
          <Route path="/join" element={<Join />} />
          <Route path="/joinSuccess" element={<JoinSuccess />} />

          <Route path="/companyInfo" element={<CompanyInfo />} />
          <Route path="/productList" element={<ProductList />} />
          <Route path="/notice" element={<Notice />} />
          <Route path="/productDetail/:productId" element={<ProductDetail />} />
          <Route
            path="/productDetailSub/:productId"
            element={<ProductDetail2 />}
          />
          <Route path="/faq" element={<Faq />} />
          <Route path="/myPage" element={<MyPage />} />
          <Route path="/myOrder" element={<MyOrder />} />
          <Route path="/orderDetail/:orderNumber" element={<OrderDetail />} />
          <Route path="/event" element={<Event />} />
          <Route path="/event/:eventId" element={<EventDetail />} />
          {/* <Route
            path="/selectTreatmentMethod/:isDirect/:cartId"
            element={<SelectTreatmentMethod />}
          /> */}
          {/* <Route path="/order/:isDirect/:cartId" element={<Order />} /> */}
          <Route path="/order/:isDirect/:cartId" element={<Order />} />
          <Route
            path="/order-continue/:orderId"
            // path="/order-continue/:cartId"
            element={<OrderContinue />}
          />
          <Route
            path="/order-addr/:isDirect/:cartId"
            element={<OrderAddress />}
          />
          {/* <Route
            path="/questionnaire/:isDirect/:cartId"
            element={<Questionnaire />}
          /> */}
          <Route
            path="/questionnaire/:isDirect/:productId"
            element={<Questionnaire />}
          />
          <Route path="/review/:productId" element={<Review />} />
          <Route path="/basket" element={<Basket />} />
          <Route path="/pick" element={<Pick />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/guide" element={<Guide />} />
          <Route
            path="/unauthorizedEmailCollection"
            element={<UnauthorizedEmailCollection />}
          />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/point" element={<Point />} />
          <Route path="/coupon" element={<Coupon />} />
          <Route path="/myReview" element={<MyReview />} />
          <Route path="/updateProfile" element={<UpdateProfile />} />
          <Route path="/myCard" element={<MyCard />} />
          <Route path="/findAccount" element={<FindAccount />} />
          <Route
            path="/deliveryDetail/:deliveryCompany/:deliveryNumber"
            element={<DeliveryDetail />}
          />
          <Route
            path="/orderConfirm/:orderNumber/:result"
            element={<OrderConfirm />}
          />
          <Route
            path="/orderConfirm/:orderNumber/:result/:errorCode"
            element={<OrderConfirm />}
          />
          <Route
            path="/orderComplete/:orderNumber"
            element={<OrderComplete />}
          />
          <Route path="/auth/kakao/callback" element={<Kakao />} />
          <Route path="/inAppGuide/:productId" element={<InAppGuide />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/getGuideURL" element={<GetGuideURL />} />
          {/* <Route path="/challenge" element={<Challenge />} /> */}
          {/* <Route path="/challengeDetail/:id" element={<ChallengeDetail />} /> */}
          {/* <Route path="/myChallenge" element={<MyChallenge />} /> */}
          <Route path="/myWeight" element={<MyWeight />} />
          <Route path="/myWeight/chart" element={<WeightChart />} />
          <Route path="/myWeight/list" element={<WeightList />} />
          <Route path="/myWeight/date" element={<WeightDate />} />
          <Route path="/myWeight/dateChart" element={<WeightDateChart />} />
          <Route path="/myWeight/point" element={<WeightPoint />} />
          <Route path="/referral" element={<Referral />} />
          <Route path="/referral-point" element={<ReferralPoint />} />
          <Route path="/program-list" element={<ProgramList />} />
          <Route path="/hospital-list/:programId" element={<HospitalList />} />
          <Route path="/non-myorder" element={<NonMyOrder />} />
        </Routes>
        <Navigation />
      </BrowserRouter>
      {isDesktop && channelAlert && <ChannelAlert />}
    </div>
  );
}

export default App;
