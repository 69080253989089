import styles from './Notice.module.scss';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { useQuery } from 'react-query';
import axiosClient from '../../libs/axiosClient';
import NoticeFrame from '../../components/NoticeFrame/NoticeFrame';
export interface INoticeType {
  body: string;
  id: number;
  start_at: string;
  title: string;
}
function Notice() {
  const { data: noticeList } = useQuery<INoticeType[]>(
    'notice',
    () =>
      axiosClient
        .get('/data/notice?notice_type=2')
        .then((res) => res.data.data),
    { refetchOnWindowFocus: false, staleTime: 60000 * 60 * 24 },
  );
  return (
    <div>
      <Header />
      <div className={styles.header}>공지사항</div>
      <ul className={styles.noticeList}>
        {noticeList && noticeList.length ? (
          noticeList?.map((notice) => (
            <li key={notice.id}>
              <NoticeFrame notice={notice} />
            </li>
          ))
        ) : (
          <p className={styles.noData}>등록된 공지사항이 존재하지 않습니다.</p>
        )}
      </ul>
      <Footer />
    </div>
  );
}
export default Notice;
