import styles from './Popup.module.scss';
import close from '../../assets/popup/close.png';
import popup from '../../assets/popup/popup.png';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useCookies } from 'react-cookie';

function Popup() {
  const [isOpen, setIsOpen] = useState(true);
  const [hasCookie, setHasCookie] = useState(true);
  const [cookies, setCookies] = useCookies();

  // 쿠키 유무 확인
  useEffect(() => {
    if (cookies['week-close']) setHasCookie(true);
    else setHasCookie(false);
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else document.body.style.overflow = 'unset';
  }, [isOpen]);

  useEffect(() => {
    if (1682866800000 > Date.now() || 1682953200000 < Date.now()) return; //5월 1일 이전, 이후엔 작동안하게
    if (
      window.location.pathname.includes('orderConfirm') ||
      window.location.pathname.includes('orderSuccess') ||
      window.location.pathname.includes('kakao')
    )
      return; //일주일간 보지 않기가 아닌 단순 닫기 버튼 누르고 타 페이지 이동 및 redirect시 결제 결과 화면에서 다시 팝업이 뜨지 않게끔

    let val = JSON.parse(localStorage.getItem('popup:Labor Day') || 'false');
    if (!val) {
      setIsOpen(true);
      return;
    }
    if (val < Date.now()) setIsOpen(true);
  }, []);

  function setOneWeekHide() {
    localStorage.setItem(
      'popup:Labor Day',
      JSON.stringify(Date.now() + 1000 * 60 * 60 * 24 * 7),
    );
    setIsOpen(false);
  }

  const handleWeekClose = () => {
    const expires = getExpiredDate(7);
    setCookies('week-close', true, { path: '/', expires });
    setIsOpen(false);
  };

  // 쿠키의 유효기한을 지정하는 함수
  const getExpiredDate = (days: number) => {
    const date = new Date();

    // 현재시간의 날짜에 days 만큼 더하여 유효기간을 지정
    date.setDate(date.getDate() + days);
    return date;
  };

  return !hasCookie && isOpen ? (
    <div className={styles.background}>
      <div className={styles.modalWrap}>
        <header>
          <span onClick={handleWeekClose}>일주일 동안 보지 않기</span>
          <img src={close} onClick={() => setIsOpen(false)} />
        </header>
        <div
          className={styles.contentWrap}
          style={{ borderRadius: isMobile ? '10px' : '' }}
        >
          <img src={popup} />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
export default Popup;
