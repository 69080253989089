import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import styles from './Referral.module.scss';
import ReferralNavigate from '../../components/ReferralNavigate/ReferralNavigate';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { String } from 'lodash';
import { isMobile, isDesktop } from 'react-device-detect';
import referralPrev from '../../assets/referralPrev.png';
import referralInvite from '../../assets/referralInvite.png';
import referralInvite2 from '../../assets/referralInvite2.png';
import referralCodeLogo from '../../assets/referralCode.png';
import referralLink from '../../assets/referralLink.png';
import copy from '../../assets/copy.png';
import pageLeft from '../../assets/pageLeft.png';
import pageRight from '../../assets/pageRight.png';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axiosClient from '../../libs/axiosClient';
import MyPageNavigate from '../../components/MyPageNavigate/MyPageNavigate';

function Referral() {
  const navigate = useNavigate();
  const [referralCode, setReferralCode] = useState<string>('');
  const [pagination, setPagination] = useState(1);
  const [paginationNumber, setPaginationNumber] = useState<number[]>([1]);
  const [referralList, setReferralList] = useState<string[]>([]);
  const [referralListNotOffset, setReferralListNotOffset] = useState<string[]>(
    [],
  );
  const [referralListCount, setReferralListCount] = useState(0); // 게시글 몇개인지
  const [referralAccumulatePoint, setReferralAccumulatePoint] = useState('0');
  const [referrerAccumulateCount, setReferrerAccumulateCount] = useState(0);

  useEffect(() => {
    axiosClient
      .get('/data/referrer/code', {
        headers: {
          'ngrok-skip-browser-warning': true,
        },
      })
      .then((res) => {
        setReferralCode(res.data.data.code);
      });
  }, []);

  function handlePaginationDown() {
    if (pagination == 1) return;
    setPagination((prev) => prev - 1);
  }
  function handlePaginationUp() {
    if (pagination >= referralListCount / 5) return;
    setPagination((prev) => prev + 1);
  }

  const getRefferList = () => {
    axiosClient
      .get(`/data/referrer?offset=${(pagination - 1) * 5}&limit=5`, {
        headers: {
          'ngrok-skip-browser-warning': true,
        },
      })
      .then((res) => {
        // console.log(res);
        setReferralList(res.data.data);
        setReferralListCount(res.data.total_count);
        setReferrerAccumulateCount(res.data.total_count);
        // let pointSum = 0;

        // for (let i = 0; i < res.data.data.length; i++) {
        //   pointSum += res.data.data[i].reward;
        // }
        // setReferralAccumulatePoint(
        //   pointSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        // );
      });
    axiosClient
      .get('/data/referrer', {
        headers: {
          'ngrok-skip-browser-warning': true,
        },
      })
      .then((res) => {
        console.log(res.data);
        // setReferralListNotOffset(res.data.data);
        let pointSum = 0;
        for (let i = 0; i < res.data.data.length; i++) {
          pointSum += res.data.data[i].reward;
        }
        setReferralAccumulatePoint(
          pointSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        );
      });
  };

  useEffect(() => {
    getRefferList();
  }, [pagination]);

  useEffect(() => {
    let list: number[] = [];
    let startPage = parseInt((pagination / 5).toString()) * 5 + 1;
    if (!(pagination % 5)) startPage -= 5;
    let endPage =
      startPage + 4 <
      referralListCount / 5 + (referralListCount % 5 !== 0 ? 1 : 0)
        ? startPage + 4
        : referralListCount / 5 + (referralListCount % 5 !== 0 ? 1 : 0);
    for (let i = startPage; i <= endPage; i++) list.push(i);
    if (!list.length) list.push(1);
    setPaginationNumber([...list]);
  }, [referralListCount, pagination]);

  return (
    <>
      <Header />
      {isMobile && (
        <div className={styles.prevWrap} onClick={() => navigate(-1)}>
          <img src={referralPrev} alt="referralPrev" />
          <span>추천인</span>
          <span></span>
        </div>
      )}
      {isDesktop && <MyPageNavigate />}
      <div
        className={`${styles.referralTopWrap} ${
          !isMobile ? styles.isDesktop : styles.isMobile
        }`}
      >
        <div className={styles.inviteWrap}>
          <img src={referralInvite2} alt="referralInvite" />
          <div>
            <p>친구 초대하고</p>
            <p>적립금 5,000원 받아가세요</p>
          </div>
        </div>
        <div
          className={`${styles.referralMsg3} ${
            !isMobile ? styles.isDesktop : styles.isMobile
          }`}
        >
          <div>
            <span>신규회원</span>
            <span>코드 입력 후, 제품 구매 시 5,000원 즉시 할인</span>
          </div>
          <div>
            <span>추천인</span>
            <span>신규회원 제품 구매 시 5,000원 즉시 지급</span>
          </div>
        </div>
        <div
          className={`${styles.referralWrap} ${
            !isMobile ? styles.isDesktop : styles.isMobile
          }`}
        >
          <div>
            <span>
              <img
                style={{
                  marginRight: '4px',
                }}
                src={referralCodeLogo}
                alt="referralCode"
              />
              추천인 코드
            </span>
            <CopyToClipboard
              text={referralCode}
              onCopy={() => console.log('복사완료')}
            >
              <span
                className={styles.refCopy}
                onClick={() => navigate('/referral')}
              >
                <span className={styles.refCode}>{referralCode}</span>
                <img src={copy} alt="copy" />
                복사
              </span>
            </CopyToClipboard>
          </div>
          <div>
            <span>
              <img
                style={{
                  marginRight: '4px',
                }}
                src={referralLink}
                alt="referralLink"
              />
              회원가입 링크
            </span>
            <CopyToClipboard
              text={`https://ondoc.co.kr/join?referral-code=${referralCode}`}
              onCopy={() => console.log('복사완료')}
            >
              <span
                className={styles.refCopy}
                onClick={() => navigate('/referral')}
              >
                <img src={copy} alt="copy" />
                복사
              </span>
            </CopyToClipboard>
          </div>
        </div>
      </div>

      <div
        className={`${styles.contentWrap} ${!isMobile ? styles.isDesktop : ''}`}
      >
        <div className={styles.referralListWrap}>
          <div
            className={`${styles.referralListTitle} ${
              !isMobile ? styles.isDesktop : ''
            }`}
          >
            <span>가입일</span>
            <span>회원아이디</span>
            <span>적립금</span>
          </div>
          {referralList.length === 0 ? (
            <div
              className={`${styles.referralListInfo2} ${
                !isMobile ? styles.isDesktop : ''
              }`}
            >
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <span></span>
                <span>나를 추천한 회원이 아직 없습니다.</span>
                <span></span>
              </div>
            </div>
          ) : (
            <div
              className={`${styles.referralListInfo2} ${
                !isMobile ? styles.isDesktop : ''
              }`}
            >
              {referralList.map((list: any, idx: any) => (
                <div key={idx}>
                  <span>
                    {list.created_at.slice(0, 10).replace(/-/gi, '.')}
                  </span>
                  {isMobile && list.user_id_str.length > 10 ? (
                    <span>{list.user_id_str.slice(0, 10)}...</span>
                  ) : (
                    <span>{list.user_id_str}</span>
                  )}
                  <span>
                    +
                    {list.reward
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={styles.paginationWrap}>
          <img src={pageLeft} alt="pageLeft" onClick={handlePaginationDown} />
          <ul>
            {paginationNumber.map((number) => (
              <li
                key={number}
                className={number == pagination ? styles.hit : ''}
                onClick={() => setPagination(number)}
              >
                {number}
              </li>
            ))}
          </ul>
          <img src={pageRight} alt="pageRight" onClick={handlePaginationUp} />
        </div>
      </div>
      <div
        className={`${styles.referralPointInviteWrap} ${
          !isMobile ? styles.isDesktop : ''
        }`}
      >
        <div>
          <p>누적 포인트</p>
          <p>{referralAccumulatePoint}P</p>
        </div>
        <div></div>
        <div>
          <p>누적 초대</p>
          <p>{referrerAccumulateCount}명</p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Referral;
