import { useState } from 'react';
import styles from './DateSelectModal.module.scss';

interface IPropsType {
  closer: React.Dispatch<React.SetStateAction<boolean>>;
  setClickText: React.Dispatch<React.SetStateAction<String>>;
  setClickYear: React.Dispatch<React.SetStateAction<Number | undefined>>;
}

const monthBtn = [
  { day: '1' },
  { day: '2' },
  { day: '3' },
  { day: '4' },
  { day: '5' },
  { day: '6' },
  { day: '7' },
  { day: '8' },
  { day: '9' },
  { day: '10' },
  { day: '11' },
  { day: '12' },
];

function DateSelectModal(props: IPropsType) {
  const [btnClickState, setBtnClickState] = useState(false);
  const [color, setColor] = useState('#000');
  const [backgroundColor, setBackgroundColor] = useState('#fff');
  const [btnText, setBtnText] = useState('');
  const date = new Date();
  const yearDate = date.getFullYear();

  const btnClick = (e: any) => {
    const text = e.target.value;
    setBtnText(e.target.value);
    console.log(btnText);
    console.log(text);
    // setBtnClickState(!btnClickState);
    // if (btnClickState === true) {
    //   setColor('#fff');
    //   setBackgroundColor('#131C31');
    // } else {
    //   setColor('#000');
    //   setBackgroundColor('#fff');
    // }
  };
  return (
    <div className={styles.dateSelectModal}>
      <div className={styles.dateSelectContent}>
        <span className={styles.closeBtn} onClick={() => props.closer(false)}>
          닫기
        </span>
        {/* <div className={styles.imageSliderWrap}>
        </div> */}
        <div className={styles.dateInfoWrap}>
          <div className={styles.dateHeaderWrap}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="37"
              height="37"
              viewBox="0 0 37 37"
              fill="none"
            >
              <path d="M22 11L15 18.5L22 26" stroke="#131C31" strokeWidth="2" />
            </svg>
            <span>{yearDate}년</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="37"
              height="37"
              viewBox="0 0 37 37"
              fill="none"
            >
              <path d="M15 26L22 18.5L15 11" stroke="#E5E5E5" strokeWidth="2" />
            </svg>
            <div></div>
          </div>
          <div className={styles.monthBtnWrap}>
            {monthBtn.map((item, idx) => (
              <button
                key={idx}
                // style={{ backgroundColor: backgroundColor, color: color }}
                onClick={btnClick}
                value={item.day}
              >
                {item.day} 월
              </button>
            ))}
          </div>
          <button
            className={styles.dateSelectBtn}
            onClick={() => {
              props.setClickText(btnText);
              props.setClickYear(yearDate);
              props.closer(false);
            }}
          >
            선택완료
          </button>
          {/* <p className={styles.content}></p> */}
        </div>
      </div>
    </div>
  );
}
export default DateSelectModal;
