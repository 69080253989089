import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import styles from './MyWeight.module.scss';
import weightList from '../../assets/weightList.jpg';
import bannerScaleImg from '../../assets/bannerScaleImg.png';
import challengeBannerDesktop from '../../assets/challengeBannerDesktop.jpg';
import challengeBannerMobile from '../../assets/challengeBannerMobile.jpg';
import challengeDetailDirectBtn from '../../assets/challengeDetailDirectBtn.png';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import axiosClient from '../../libs/axiosClient';
import ChallengeSubmitModal from '../../components/ChallengeSubmitModal/ChallengeSubmitModal';

function MyChallenge2() {
  const navigate = useNavigate();
  const { challengeNumber } = useParams();
  const date = new Date();

  const [inputWeekData, setInputWeekData] = useState<any[]>([]); //일주일동안 이미 입력된 데이터 확인
  const [startInputDate, setStartInputDate] = useState();
  // const [lastInputDate, setLastInputDate] = useState<String>();
  const [inputWeight, setInputWeight] = useState('');
  const [todayDate, setTodayDate] = useState('');
  const [weekDay, setWeekDay] = useState([
    '일',
    '월',
    '화',
    '수',
    '목',
    '금',
    '토',
  ]);
  const [submitMsg, setSubmitMsg] = useState(''); // 몸무게 입력시 확인 문구
  const [isInputFocused, setIsInputFocused] = useState(false); // input focus
  const [challengePartiDay, setChallengePartiDay] = useState(); // 챌린지 참여일
  const [challengeSubmitModalOpen, setChallengeSubmitModalOpen] =
    useState(false); // Modal Open 여부
  const [challengeParticipation, setChallengeParticipation] =
    useState<String>();
  const [challengeNextStartDay, setChallengeNextStartDay] = useState<String>();
  const [challengeNextEndDay, setChallengeNextEndDay] = useState<String>();

  // Next Start End Day
  let startDay;
  let endDay;

  const onChangeWeight = (e: any) => {
    const weightValue = e.target.value;
    const weightNumber = weightValue.replace(/[^0-9.]/g, ''); // 숫자와 .만 입력되도록
    const weightZeroRem = weightNumber.replace(/(^0+)/, ''); // 맨처음 0 삭제
    setInputWeight(weightZeroRem);
    // setInputWeight(e.target.value);
  };

  const onKeyUpWeight = (e: any) => {
    let weightValue = e.target.value;

    // 맨처음 . 입력 x
    if (weightValue.charAt(0) === '.') {
      weightValue = weightValue.substring(1);
    }

    // . 총 1번만
    const parts = weightValue.split('.');
    if (parts.length > 2) {
      weightValue = parts[0] + '.' + parts[1];
    }

    // 정수부분은 3자리까지, 소수점 아래는 2자리까지만 입력
    const regex = /^\d{0,3}(\.\d{0,2})?$/;
    if (!regex.test(weightValue)) {
      weightValue = weightValue.match(/^\d{0,3}(\.\d{0,2})?/)[0];
    }

    setInputWeight(weightValue);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    // 입력 몸무게 숫자 -> 문자
    const weight = e.target.weight.value.toString();

    // forData이용하여 몸무게 저장
    let formData = new FormData();
    formData.append('content', weight);

    // 소수점 2자리까지 숫자
    const weightRegux = /^(?!0\d)([1-9]\d*|0)(\.\d{1,2})?$/;

    if (weight === '') return;
    if (weightRegux.test(weight) && challengeParticipation === 'N') {
      setChallengeParticipation('Y');
      // 몸무게 등록
      axiosClient.post('/func/challenge/1/apply', formData).then((res) => {
        // if (inputWeight !== '') {
        //   setSubmitMsg('체중이 입력됐어요 :)');
        //   setInputWeight('');
        // }
        setChallengeSubmitModalOpen(true);
        axiosClient.get(`/data/challenge/my/1`).then(function (res) {
          // console.log(res.data.challenge_log);
          setChallengePartiDay(res.data.challenge_participation_period);
        });
      });
    } else {
      alert('몸무게를 올바르게 작성해주세요.');
    }
  };

  // input란 focus 메시지 숨김
  const handleInputFocus = () => {
    setIsInputFocused(true);
    setSubmitMsg('');
  };

  // input란 focus 빠지면서 메시지 나타냄
  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  useEffect(() => {
    // 몸무게 조회 (주별)
    axiosClient.get(`/data/challenge/my/1`).then(function (res) {
      // console.log(res.data.challenge_log);
      setInputWeekData(res.data.challenge_log);
      setChallengePartiDay(res.data.challenge_participation_period);
      setStartInputDate(
        res.data.challenge_log[res.data.challenge_log.length - 1].start_date
          .slice(5, 10)
          .replace(/-/gi, '월 '),
      );
    });

    // 몸무게 참여여부 조회
    axiosClient
      .get(`/data/challenge/my/1/is_participated`)
      .then(function (res) {
        setChallengeParticipation(res.data.data.is_participated);
        startDay = new Date(res.data.data.next_start_date);
        endDay = new Date(res.data.data.next_end_date);
        setChallengeNextStartDay(
          startDay.getMonth() + 1 + '월 ' + startDay.getDate() + '일',
        );
        setChallengeNextEndDay(
          endDay.getMonth() + 1 + '월 ' + endDay.getDate() + '일',
        );
      });

    //오늘 날짜 계산
    const date = new Date();
    const today =
      date.getMonth() +
      1 +
      '월 ' +
      date.getDate() +
      '일 ' +
      weekDay[date.getDay()] +
      '요일';
    setTodayDate(today);
  }, []);

  return (
    <>
      <Header></Header>
      <div>
        {isDesktop ? (
          <img
            style={{
              width: '100%',
            }}
            src={challengeBannerDesktop}
            alt="challengeBannerDesktop"
          />
        ) : (
          <img
            style={{
              width: '100%',
            }}
            src={challengeBannerMobile}
            alt="challengeBannerMobile"
          />
        )}
      </div>
      <div
        className={`${styles.contentWrap} ${!isMobile ? styles.isDesktop : ''}`}
      >
        <div className={styles.title}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <circle cx="9" cy="9" r="7" stroke="black" />
            <path d="M9 5V9H13" stroke="black" strokeLinecap="square" />
          </svg>
          <span>{todayDate}</span>
          <h2>체중 감량 챌린지</h2>
          <p>나의 체중 입력하기</p>
        </div>
        <div className={styles.weightForm}>
          <form onSubmit={onSubmit}>
            <div className={styles.weightInputWrap}>
              {challengeParticipation === 'Y' ? (
                <input
                  value={''}
                  placeholder="체중을 입력해주세요   000.00"
                  disabled
                />
              ) : (
                <input
                  type="text"
                  name="weight"
                  value={inputWeight}
                  onChange={onChangeWeight}
                  onKeyUp={onKeyUpWeight}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  placeholder="체중을 입력해주세요   000.00"
                />
              )}
              <span>kg</span>
            </div>
            {challengeParticipation === 'Y' ? (
              <button
                type="button"
                className={styles.unableBtn}
                disabled={false}
              >
                입력하기
              </button>
            ) : (
              <button
                type="submit"
                className={`${styles.inputBtn} ${
                  isMobile ? styles.inputBtnMobile : ''
                }`}
              >
                입력하기
              </button>
            )}
          </form>
          {/* <div className={styles.inputCheck}>{submitMsg}</div> */}
        </div>
        {challengeParticipation === 'Y' && isMobile && (
          <div
            className={`${styles.bannerWrap} ${
              isMobile ? styles.isMobile : styles.isDesktop
            }`}
          >
            <div className={styles.bannerTextOk}>
              <p>이번 주</p>
              <span>체중이 입력 완료</span>
              <span>됐습니다.</span>
            </div>
            <div className={styles.bannerTextDate}>
              <span>다음 입력 기간: </span>
              <span>
                {challengeNextStartDay} ~ {challengeNextEndDay}
              </span>
            </div>
            <div className={styles.bannerImg}>
              <img src={bannerScaleImg} alt="weightInputOk" />
            </div>
          </div>
        )}
        {challengeParticipation === 'Y' && isDesktop && (
          <div
            className={`${styles.bannerWrap} ${
              isMobile ? styles.isMobile : styles.isDesktop
            }`}
          >
            <div className={styles.bannerTextOk}>
              <span>이번 주 </span>
              <span>체중이 입력 완료</span>
              <span>됐습니다.</span>
            </div>
            <div className={styles.bannerTextDate}>
              <span>다음 입력 기간: </span>
              <span>
                {challengeNextStartDay} ~ {challengeNextEndDay}
              </span>
            </div>
            <div className={styles.bannerImg}>
              <img src={bannerScaleImg} alt="weightInputOk" />
            </div>
          </div>
        )}
        <div
          className={`${styles.challengeDetailDirectWrap} ${
            isMobile ? styles.isMobile : ''
          }`}
          onClick={() => navigate('/event/22')}
        >
          <span>체중 감량 챌린지 자세히보기</span>
          <img src={challengeDetailDirectBtn} alt="direct" />
        </div>
        <div className={styles.weightListWrap}>
          <div>
            <p className={styles.weightListTitle}>나의 기록 변화</p>
            {challengePartiDay ? (
              <p className={styles.wieghtListSubTitle}>
                {challengePartiDay}일째 챌린지 참여 중이에요!
              </p>
            ) : (
              <p className={styles.wieghtListSubTitle}>
                0일째 챌린지 참여 중이에요!
              </p>
            )}
            {/* <p className={styles.wieghtListSubTitle}>
              {challengePartiDay}일째 챌린지 참여 중이에요!
            </p> */}
          </div>
          {/* {inputWeekData && ( */}
          <div
            className={`${styles.weightListBtnWrap} ${
              isDesktop ? styles.isDesktop : ''
            }`}
          >
            <button
              className={styles.graphBtn}
              onClick={() => navigate('/myWeight/chart')}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <rect
                  x="7"
                  y="8"
                  width="26"
                  height="24"
                  stroke="#818DA9"
                  strokeWidth="3"
                />
                <path d="M12 14H15" stroke="#818DA9" strokeWidth="2" />
                <path d="M12 20H15" stroke="#818DA9" strokeWidth="2" />
                <path d="M12 26H15" stroke="#818DA9" strokeWidth="2" />
                <path d="M17 14H28" stroke="#818DA9" strokeWidth="2" />
                <path d="M17 20H28" stroke="#818DA9" strokeWidth="2" />
                <path d="M17 26H28" stroke="#818DA9" strokeWidth="2" />
              </svg>
              <p>그래프보기</p>
            </button>
            <button
              className={styles.listBtn}
              onClick={() => navigate('/myWeight/list')}
            >
              <img src={weightList} alt="listimg" />
              <p>리스트보기</p>
            </button>
            <button
              className={styles.pointBtn}
              onClick={() => navigate('/myWeight/point')}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <path
                  d="M20 31V17.5M30.5 31V17.5H9.5V31H30.5Z"
                  stroke="#818DA9"
                  strokeWidth="3"
                  strokeLinecap="square"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 11.5H32V17.5H8V11.5Z"
                  stroke="#818DA9"
                  strokeWidth="3"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.5 7L20 11.5L24.5 7"
                  stroke="#818DA9"
                  strokeWidth="3"
                  strokeLinecap="square"
                  strokeLinejoin="round"
                />
                <path
                  d="M25 34V35H26H28.9688H29.9688V34V31.6878H30.7175C32.1934 31.6919 33.5192 31.2687 34.4899 30.4074C35.4734 29.5347 36 28.2923 36 26.8591C36 25.4511 35.4901 24.2098 34.5342 23.3263C33.5856 22.4495 32.2803 22 30.8125 22H26H25V23V34ZM30.1212 27.4392H29.9688V26.3094H30.1213C30.5142 26.3126 30.6976 26.4158 30.7685 26.4783C30.8215 26.5251 30.8926 26.6165 30.8907 26.851L30.8906 26.8591L30.8907 26.8672C30.8926 27.1093 30.8186 27.2125 30.7593 27.2661C30.6863 27.3321 30.5049 27.4361 30.1212 27.4392Z"
                  fill="#818DA9"
                  stroke="white"
                  strokeWidth="2"
                />
              </svg>
              <p>리워드보기</p>
            </button>
          </div>
        </div>
      </div>
      {challengeSubmitModalOpen && (
        <ChallengeSubmitModal closer={setChallengeSubmitModalOpen} />
      )}
      <Footer></Footer>
    </>
  );
}

export default MyChallenge2;
