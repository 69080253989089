import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';

function UnauthorizedEmailCollection() {
  return (
    <div>
      <Header />
      <div style={{ padding: '64px 0' }}>
        <h3
          style={{
            color: '#000',
            fontSize: '29px',
            letterSpacing: '-1px',
            textAlign: 'center',
            fontWeight: '600',
            paddingBottom: '32px',
          }}
        >
          이메일무단수집거부
        </h3>
        <div
          dangerouslySetInnerHTML={{
            __html: `<div class="editor">
            본사이트에 게시된 이메일 주소가 전자우편 수집프로그램이나 그밖의 기술적 장치를 이용하여 무단으로 수집 되는 것을 거부하며, 이를 위반시 정보통신망법에 의해 형사처벌됨을 유념하시기 바랍니다.<br><p>
    정보통신망이용 및 정보보호 등에 관한법률(일부개정 2002.12.18 법률 제0697호)</p><br><br>
    
    <p style="font-size:15px; color:#000; letter-spacing:-0.5px; font-weight:600;">제 50조의 2(전자우편주소의 무단수집행위 등 금지)</p><br>
    <p>• 누구든지 전자우편주소의 수집을 거부하는 의사가 명시된 인터넷 홈페이지에서 자동으로 전자우편주소를 수집하는 프로그램 그 밖의 기술적 장치를 이용하여 전자우편주소를 수집하여서는 아니된다.<br>
    • 누구든지 제1항의 규정을 위반하여 수집된 전자우편주소를 판매 유통하여서는 아니된다.<br>
    • 누구든지 제1항 및 제2항의 규정에 의하여 수집 판매 및 유통이 금지된 전자우편주소임을 알고 이를 정보전송에 이용하여서는 아니된다.</p><br><br>
    
    <p style="font-size:15px; color:#000; letter-spacing:-0.5px; font-weight:600;">제 65조의 2(벌칙)다음각호의 1에 해당하는 자는 1천만원이하의 벌금에 처한다.</p><br>
    <p>• 제65조의 2(벌칙) 다음 각호의 1에 해당하는 자는 1천만원 이하의 벌금에 처한다.<br>
    • 제50조 제4항의 규정을 위반하여 기술적 조치를 한자<br>
    • 제50조 제6항의 규정을 위반하여 영리목적의 광고성 정보를 전송한 자<br>
    • 제50조 제2항의 규정을 위반하여 전자우편주소를 수집 · 판매 · 유통 또는 정보전송에 이용한자&ZeroWidthSpace;</p>
    
    <br><br>
    <p style="font-size:15px; color:#000; letter-spacing:-0.5px; font-weight:600;"><span style="font-size: 11pt;">게시일:&nbsp;</span><u style="font-size: 12px;"><a href="#none" class="js_insert_text" style="font-size: 12px;"><span style="font-size: 11pt;">2022-11-04 16:33:22</span></a></u></p>			</div>`,
          }}
        ></div>
      </div>
      <Footer />
    </div>
  );
}
export default UnauthorizedEmailCollection;
