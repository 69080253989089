import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import styles from './MyReview.module.scss';
import imsiProduct from '../../assets/imsi/imsiProduct4.png';
import WriteReviewModal from '../../components/WriteReviewModal/WriteReviewModal';
import { useQuery } from 'react-query';
import ReviewDetailModal from '../../components/ReviewDetailModal/ReviewDetailModal';
import ReviewYetTab from '../../components/ReviewYetTab/ReviewYetTab';
import MyReviewTab from '../../components/MyReviewTab/MyReviewTab';
import MyPageNavigate from '../../components/MyPageNavigate/MyPageNavigate';
import { isDesktop } from 'react-device-detect';
import leftArrow from '../../assets/leftArrow02.png';
import LoginHandler from '../../components/LoginHandler/LoginHandler';

export interface IReviewYetType {
  delivery_complete_at: string;
  list_image: string;
  order_product_id: number;
  product_id: number;
  product_name: string;
  review_limit_at: string;
  op_option1: string | null;
  op_option2: string | null;
  op_option3: string | null;
}
export interface IReviewDataType {
  age: number;
  answer: string;
  choice_label: number;
  choice_label_text: string;
  content: string;
  count: number;
  created_at: string;
  depth: number;
  gender: string;
  id: number;
  images: {
    created_at: string;
    id: number;
    imagable_id: number;
    imagable_type: string;
    image: string;
    updated_at: null | string;
  }[];
  is_answered: string;
  is_secret: string;
  op_option1: string | null;
  op_option2: string | null;
  op_option3: string | null;
  options: string;
  order: number;
  parent_id: number;
  product: {
    brand_id: null | string;
    brand_image: null | string;
    brand_name: null | string;
    company_id: string;
    company_image: null | string;
    company_name: null | string;
    cp_delivery_freeprice: number;
    cp_delivery_price: number;
    cp_delivery_use: string;
    delivery_cost: number;
    delivery_type: string;
    detail_url: string;
    discount_price: number;
    discount_rate: number;
    hashtag: string;
    id: string;
    image: string;
    is_like: number;
    is_prescription: number;
    list_image: string;
    option_depth: number;
    p_shoppingPayFree: number;
    p_shoppingPayPdPrice: number;
    p_shoppingPayPfPrice: number;
    price: number;
    product_id: number;
    review_score_avg: {
      product_id: number;
      review_point_0_avg: number;
      review_point_1_avg: number;
      review_point_2_avg: number;
      review_point_3_avg: number;
      review_point_total_avg: number;
      review_total_count: number;
    };
    soldout: string;
    stock_count: number;
    title: string;
  };

  member_id: string;
  product_id: number;
  stars0: number;
  stars1: number;
  stars2: number;
  stars3: number;
  title: string;
  user_id: number;
  view_count: string;
  weight: number;
  writer: string;
}
function MyReview() {
  const navigate = useNavigate();
  const [category, setCategory] = useState(0);

  return (
    <div className={styles.container}>
      <LoginHandler />
      <Header />
      <div className={styles.contentWrap}>
        {isDesktop && <MyPageNavigate />}
        <h3 className={styles.title}>
          {!isDesktop && (
            <img src={leftArrow} alt="leftArrow" onClick={() => navigate(-1)} />
          )}
          <span>나의 후기</span>
        </h3>
        <ul className={styles.categoryWrap}>
          <li
            onClick={() => setCategory(0)}
            className={category == 0 ? styles.hit : ''}
          >
            작성 가능한 후기
          </li>
          <li
            onClick={() => setCategory(1)}
            className={category == 1 ? styles.hit : ''}
          >
            내가 쓴 후기
          </li>
        </ul>
        {category == 0 ? <ReviewYetTab /> : <MyReviewTab />}
      </div>
      <Footer />
    </div>
  );
}
export default MyReview;
