import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import styles from './ReferralPoint.module.scss';
import ReferralNavigate from '../../components/ReferralNavigate/ReferralNavigate';

function ReferralPoint() {
  return (
    <>
      <Header />
      <div className={styles.contentWrap}>
        <ReferralNavigate />
      </div>
      <Footer />
    </>
  );
}

export default ReferralPoint;
