import styles from './SideMenuMobile.module.scss';
import closeBtn from '../../assets/closeBtn02.png';
import plus from '../../assets/plus.png';
import minus from '../../assets/minus.png';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ondoc from '../../assets/ondoc.png';
import jwt_decode from 'jwt-decode';
import { ITokenInfoType, loginCheck, logout } from '../../auth/auth';
import {
  getRedirectUrl,
  loginConfirm,
  loginConfirmRurl,
} from '../../common/loginConfirm';
import { link } from 'fs';

interface IPropsType {
  isOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
function SideMenuMobile(props: IPropsType) {
  const [productOpen, setProductOpen] = useState(false);
  const [communityOpen, setCommunityOpen] = useState(false);
  const [userName, setUserName] = useState('');
  const [isLogin, setIsLogin] = useState(false);
  useEffect(() => {
    let token = localStorage.getItem('token');
    if (!token || token == 'null') return;
    try {
      const userInfo: ITokenInfoType = jwt_decode(token!);
      setUserName(userInfo.name);
    } catch (err: any) {
      logout();
    }
  }, []);
  useEffect(() => {
    setIsLogin(loginCheck());
  }, []);
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.contentWrap}>
        <img
          src={closeBtn}
          alt="closeBtn"
          className={styles.closeBtn}
          onClick={() => props.isOpen(false)}
        />
        <div className={styles.userInfo}>
          <div className={styles.profileImage}>
            <img src={ondoc} alt="ondoc" />
          </div>
          {isLogin ? (
            <span>{userName}님 환영합니다.</span>
          ) : (
            <span onClick={() => navigate(`/login?rurl=${getRedirectUrl()}`)}>
              로그인이 필요합니다.
            </span>
          )}
        </div>
        <div className={styles.firstMenuWrap}>
          <ul>
            <li>
              <div
                className={styles.title}
                onClick={() => navigate('/companyInfo')}
              >
                <span>온닥</span>
              </div>
            </li>
            <li>
              <div
                className={styles.title}
                onClick={() => {
                  if (!loginConfirmRurl('/myWeight')) return;
                  navigate('/myWeight');
                }}
              >
                <span>챌린지</span>
              </div>
            </li>
            <li>
              <div
                className={styles.title}
                onClick={() => navigate('/program-list')}
              >
                <span>병원진료</span>
              </div>
            </li>
            <li>
              <div
                className={styles.title}
                onClick={() => navigate('/productList')}
              >
                <span>상품</span>
                {/* <img src={productOpen ? minus : plus} alt="opener" /> */}
              </div>
              {/* <ul
                className={`${productOpen ? styles.isOpen : ''} ${
                  styles.productList
                }`}
              >
                <li onClick={() => navigate('/productList')}>병원진료</li>
                <li onClick={() => navigate('/productList?cuid=487')}>
                  처방의약품
                </li>
                <li onClick={() => navigate('/productList?cuid=488')}>
                  건강식품
                </li>
              </ul> */}
            </li>
            <li>
              <div
                className={styles.title}
                onClick={() => setCommunityOpen((prev) => !prev)}
              >
                <span>커뮤니티</span>
                <img src={communityOpen ? minus : plus} alt="opener" />
              </div>
              <ul
                className={`${communityOpen ? styles.isOpen : ''} ${
                  styles.communityList
                }`}
              >
                <li onClick={() => navigate('/notice')}>공지사항</li>
                <li onClick={() => navigate('/event')}>이벤트</li>
                <li
                  onClick={() => {
                    if (!loginConfirmRurl('/review/all')) return;
                    navigate('/review/all');
                  }}
                >
                  상품후기
                </li>
                <li onClick={() => navigate('/faq')}>FAQ</li>
              </ul>
            </li>
            {!isLogin && (
              <li>
                <div
                  className={`${styles.title} ${styles.nonMember}`}
                  onClick={() => navigate('/non-myorder')}
                >
                  <span>비회원 주문 조회</span>
                </div>
              </li>
            )}
          </ul>
        </div>
        <div className={styles.secondMenuWrap}>
          <ul>
            <li
              onClick={() => {
                if (!loginConfirmRurl('/basket')) return;
                navigate('/basket');
              }}
            >
              cart
            </li>
            <li
              onClick={() => {
                if (!loginConfirmRurl('/myOrder')) return;
                navigate('/myOrder');
              }}
            >
              order
            </li>
            <li
              onClick={() => {
                if (!loginConfirmRurl('/myPage')) return;
                navigate('/myPage');
              }}
            >
              mypage
            </li>
            {isLogin ? (
              <li
                onClick={() => {
                  if (!localStorage.getItem('mobileApp')) {
                    logout();
                    window.location.href = '/';
                    return;
                  }
                  window.flutter_inappwebview
                    .callHandler('logout')
                    .then((res: any) => {
                      props.isOpen(false);
                      navigate('/');
                    })
                    .catch((err: any) => {
                      if (
                        err.response &&
                        err.response.data &&
                        err.response.data.detail
                      )
                        return err.response.data.detail;
                    });
                }}
              >
                logout
              </li>
            ) : (
              <li onClick={() => navigate('/join')}>join</li>
            )}
          </ul>
        </div>
        <p className={styles.ondoc}>
          온닥은 쉽고 편리한 진료 서비스를 제공합니다.
        </p>
      </div>
    </div>
  );
}
export default SideMenuMobile;
