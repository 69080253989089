import { useEffect, useRef, useState } from 'react';
import Header from '../../components/Header/Header';
import styles from './WeightChart.module.scss';
import Chart from 'react-apexcharts';
import { useLocation, useNavigate } from 'react-router-dom';
import { isDesktop, isMobile } from 'react-device-detect';
import axiosClient from '../../libs/axiosClient';
import pointImg from '../../assets/pointImg.png';
import list from '../../assets/weightChallenge/list.png';
import prevBtn from '../../assets/weightChallenge/prevBtn.png';
import dateLeft from '../../assets/weightChallenge/dateLeft.png';
import dateRight from '../../assets/weightChallenge/dateRight.png';
import dateLeftNo from '../../assets/weightChallenge/dateLeftNo.png';
import dateRightNo from '../../assets/weightChallenge/dateRightNo.png';

export interface IWeightType {
  content: string;
}

function WeightChart() {
  const navigate = useNavigate();
  const location = useLocation();
  const [tabMenuSelect, setTabMenuSelect] = useState('');
  const [weeklyData, setWeeklyData] = useState<any[]>([]);
  const [monthlyData, setMonthlyData] = useState<any[]>([]);
  const movingBarRef = useRef<HTMLDivElement>(null);
  const [monthState, setMonthState] = useState<Number>(); // 월 구하기
  const [yearState, setYearState] = useState<Number>(); // 년도 구하기
  const [challengePartiDay, setChallengePartiDay] = useState();
  const [avgWeekWeight, setAvgWeekWeight] = useState<Number>(0); // 주별 평균
  const [loseWeightWeek, setLoseWeightWeek] = useState<String>('0'); // 주별 총감량
  const [avgMonthWeight, setAvgMonthWeight] = useState<Number>(0); // 월별 평균
  const [loseWeightMonth, setLoseWeightMonth] = useState<String>('0'); // 월별 총감량
  const [activeItem, setActiveItem] = useState('weekChart');

  // 평균 체중 변수
  let sum = 0;
  let avg;

  // 총 감량 체중 변수
  let loseSum = 0;

  // 평균 체중 변수(월별)
  let sumMonth = 0;
  let avgMonth;

  // 총 감량 체중 변수(월별)
  let loseSumMonth = 0;

  let weeklyWeightFilter: string | any[] = [];
  let monthlyWeightFilter: string | any[] = [];

  let weekStartDate: string | number | Date;
  let weekEndDate: string | number | Date;
  let monthStartDate: string | number | Date;
  let monthEndDate: string | number | Date;
  let challengeLogLength: number;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (!params.get('date-tab')) setTabMenuSelect('주별');
    else if (params.get('date-tab') === 'month') {
      setTabMenuSelect('월별');
      setActiveItem('monthChart');
    }
  }, [location]);

  const weekAvgWeightLoseWeight = () => {
    sum = 0;
    loseSum = 0;

    if (weeklyWeightFilter.length !== 0) {
      for (let i = 0; i < weeklyWeightFilter.length; i++) {
        sum += Number(weeklyWeightFilter[i].content);
        loseSum += Number(weeklyWeightFilter[i].loseweight);
      }

      avg = (sum / weeklyWeightFilter.length).toFixed(2);
      setAvgWeekWeight(Number(avg));
      if (Number(loseSum.toFixed(2)) > 0)
        setLoseWeightWeek('+' + Number(loseSum.toFixed(2)));
      else if (Number(loseSum.toFixed(2)) < 0)
        setLoseWeightWeek(Number(loseSum.toFixed(2)).toString().slice(1));
      else setLoseWeightWeek('0');
    } else {
      setAvgWeekWeight(0);
      setLoseWeightWeek('0');
    }
  };

  const monthAvgWeightLoseWeight = () => {
    sumMonth = 0;
    loseSumMonth = 0;

    if (monthlyWeightFilter.length !== 0) {
      for (let i = 0; i < monthlyWeightFilter.length; i++) {
        sumMonth += Number(monthlyWeightFilter[i].content);
        loseSumMonth += Number(monthlyWeightFilter[i].loseweight);
      }
      avgMonth = (sumMonth / monthlyWeightFilter.length).toFixed(2);
      setAvgMonthWeight(Number(avgMonth));
      if (Number(loseSumMonth.toFixed(2)) > 0)
        setLoseWeightMonth('+' + Number(loseSumMonth.toFixed(2)));
      else if (Number(loseSumMonth.toFixed(2)) < 0)
        setLoseWeightMonth(Number(loseSumMonth.toFixed(2)).toString().slice(1));
      else setLoseWeightMonth('0');
    } else {
      setAvgMonthWeight(0);
      setLoseWeightMonth('0');
    }
  };

  const weeklyDataConditionImport = () => {
    axiosClient
      .get(`/data/challenge/my/1?date_str=${nowYear}-${nowMonth}-01`)
      .then(function (res) {
        setWeeklyData(res.data.challenge_log);
        setChallengePartiDay(res.data.challenge_participation_period);
        weeklyWeightFilter = res.data.challenge_log.filter(
          (item: any) => item.content !== '0.00',
        );
        weekAvgWeightLoseWeight();
      });
  };

  const weeklyDataImport = () => {
    axiosClient.get(`/data/challenge/my/1`).then(function (res) {
      // axiosClient
      //   .get(`/data/challenge/my/1?date_str=2024-12-01`)
      //   .then(function (res) {
      challengeLogLength = res.data.challenge_log.length;
      weekStartDate = res.data.challenge_log[0].start_date;
      weekEndDate = res.data.challenge_log[challengeLogLength - 1].end_date;

      if (nowDate < new Date(weekStartDate)) {
        if (nowMonth !== 1) {
          nowMonth -= 1;
          setMonthState(nowMonth);
        } else {
          nowMonth = 12;
          nowYear -= 1;
          setMonthState(nowMonth);
          setYearState(nowYear);
        }
        monthRef.current = nowMonth;
        yearRef.current = nowYear;
      } else if (nowDate > new Date(weekEndDate)) {
        if (nowMonth !== 12) {
          nowMonth += 1;
          setMonthState(nowMonth);
        } else {
          nowMonth = 1;
          nowYear += 1;
          setMonthState(nowMonth);
          setYearState(nowYear);
        }
        monthRef.current = nowMonth;
        yearRef.current = nowYear;
      } else {
        monthRef.current = nowMonth;
        yearRef.current = nowYear;
        setWeeklyData(res.data.challenge_log);
        setChallengePartiDay(res.data.challenge_participation_period);
        weeklyWeightFilter = res.data.challenge_log.filter(
          (item: any) => item.content !== '0.00',
        );
        weekAvgWeightLoseWeight();
        return;
      }
      weeklyDataConditionImport();
    });
  };

  const monthlyDataConditionImport = () => {
    axiosClient
      .get(`/data/challenge/my/1?date_str=${nowYear}-01-01&unit=monthly`)
      .then(function (res) {
        setMonthlyData(res.data.challenge_log);
        monthlyWeightFilter = res.data.challenge_log.filter(
          (item: any) => item.content !== '0.00',
        );
        monthAvgWeightLoseWeight();
      });
  };

  const monthlyDataImport = () => {
    axiosClient.get(`/data/challenge/my/1?unit=monthly`).then(function (res) {
      // axiosClient
      //   .get(`/data/challenge/my/1?date_str=2024-01-01&unit=monthly`)
      //   .then(function (res) {
      challengeLogLength = res.data.challenge_log.length;
      monthStartDate = res.data.challenge_log[0].start_date;
      monthEndDate = res.data.challenge_log[challengeLogLength - 1].end_date;

      if (nowDate < new Date(monthStartDate)) {
        if (nowYear === nowYearStore) nowYear -= 1;
        yearRef.current = nowYear;
        setYearState(nowYear);
      } else if (nowDate > new Date(monthEndDate)) {
        if (nowYear === nowYearStore) nowYear += 1;
        yearRef.current = nowYear;
        setYearState(nowYear);
      } else {
        yearRef.current = nowYear;
        setMonthlyData(res.data.challenge_log);
        monthlyWeightFilter = res.data.challenge_log.filter(
          (item: any) => item.content !== '0.00',
        );
        monthAvgWeightLoseWeight();
        return;
      }
      monthlyDataConditionImport();
    });
  };

  useEffect(() => {
    // 몸무게 조회 (주별)
    weeklyDataImport();

    // 몸무게 조회 (월별)
    monthlyDataImport();
  }, []);

  let monthRef = useRef<number>();
  let yearRef = useRef<number>();

  // 날짜지정하기
  const nowDate = new Date();
  // const nowDate = new Date('2024-12-30');
  let nowYear = nowDate.getFullYear();
  let nowMonth = nowDate.getMonth() + 1;

  let nowYearStore = nowDate.getFullYear();
  useEffect(() => {
    const month = nowDate.getMonth() + 1;
    setMonthState(month);

    const year = nowDate.getFullYear();
    setYearState(year);
  }, []);

  // 주별 날짜 변경
  const dateWeekClick = (dateNum: number) => {
    let monthStateChange = Number(monthState);
    let yearStateChange = Number(yearState);

    if (dateNum === -1) {
      if (Number(monthState) > 1 && Number(monthState) < 13) {
        setMonthState(Number(monthState) - 1);
        monthStateChange = Number(monthState) - 1;
      } else if (Number(monthState) === 1) {
        setMonthState(Number(monthState) + 11);
        setYearState(Number(yearState) - 1);
        monthStateChange = Number(monthState) + 11;
        yearStateChange = Number(yearState) - 1;
      }
    } else if (dateNum === +1) {
      if (Number(monthState) > 0 && Number(monthState) < 12) {
        monthStateChange = Number(monthState) + 1;
        setMonthState(Number(monthState) + 1);
      } else if (Number(monthState) === 12) {
        monthStateChange = Number(monthState) - 11;
        yearStateChange = Number(yearState) + 1;
        setMonthState(Number(monthState) - 11);
        setYearState(Number(yearState) + 1);
      }
    }

    const monthStateChangeZero =
      monthStateChange <= 9 ? '0' + monthStateChange : monthStateChange;

    axiosClient
      .get(
        `/data/challenge/my/1?date_str=${yearStateChange}-${monthStateChangeZero}-01`,
      )
      .then(function (res) {
        setWeeklyData(res.data.challenge_log);
        weeklyWeightFilter = res.data.challenge_log.filter(
          (item: any) => item.content !== '0.00',
        );

        weekAvgWeightLoseWeight();
      });
  };

  // 월별 날짜 변경
  const dateMonthClick = (dateNum: number) => {
    let yearStateChange = Number(yearState);

    if (dateNum === -1) {
      setYearState(Number(yearState) - 1);
      yearStateChange -= 1;
    } else if (dateNum === +1) {
      setYearState(Number(yearState) + 1);
      yearStateChange += 1;
    }

    axiosClient
      .get(
        `/data/challenge/my/1?date_str=${yearStateChange}-01-01&unit=monthly`,
      )
      .then(function (res) {
        setMonthlyData(res.data.challenge_log);
        monthlyWeightFilter = res.data.challenge_log.filter(
          (item: any) => item.content !== '0.00',
        );
        monthAvgWeightLoseWeight();
      });
  };

  const tabMenuClick = (item: string) => {
    setActiveItem(item);
    setYearState(nowYear);
    setMonthState(nowMonth);

    if (item === 'weekChart') {
      weeklyDataImport();
      navigate('/myWeight/chart');
    } else if (item === 'monthChart') {
      monthlyDataImport();
      navigate('/myWeight/chart?date-tab=month');
    }
  };

  return (
    <>
      <Header />
      {isMobile && (
        <div className={styles.prevWrap} onClick={() => navigate('/myWeight')}>
          <img src={prevBtn} alt={prevBtn} />
        </div>
      )}
      <div className={styles.navigation}>
        <ul>
          <li
            className={activeItem === 'weekChart' ? styles.active : ''}
            onClick={() => tabMenuClick('weekChart')}
          >
            <span>주별</span>
          </li>
          <li
            className={activeItem === 'monthChart' ? styles.active : ''}
            onClick={() => tabMenuClick('monthChart')}
          >
            <span>월별</span>
          </li>
          <div ref={movingBarRef} className={styles.movingBar}></div>
        </ul>
      </div>
      <main
        className={`${styles.contentWrap} ${!isMobile ? styles.isDesktop : ''}`}
      >
        <div
          className={`${styles.dateWrap} ${!isMobile ? styles.isDesktop : ''}`}
        >
          {tabMenuSelect === '주별' && (
            <h3>
              {Number(yearState) <= 2023 && Number(monthState) <= 9 ? (
                <img src={dateLeftNo} alt={dateLeftNo} />
              ) : (
                <img
                  onClick={() => dateWeekClick(-1)}
                  src={dateLeft}
                  alt={dateLeft}
                />
              )}
              {yearState?.toString()}년 {monthState?.toString()}월
              {Number(yearState) === yearRef.current &&
              Number(monthState) === monthRef.current ? (
                <img src={dateRightNo} alt={dateRightNo} />
              ) : (
                <img
                  onClick={() => dateWeekClick(+1)}
                  src={dateRight}
                  alt={dateRight}
                />
              )}
            </h3>
          )}
          {tabMenuSelect === '월별' && (
            <h3>
              {Number(yearState) <= 2023 ? (
                <img src={dateLeftNo} alt={dateLeftNo} />
              ) : (
                <img
                  onClick={() => dateMonthClick(-1)}
                  src={dateLeft}
                  alt={dateLeft}
                />
              )}
              {yearState?.toString()}년
              {Number(yearState) === yearRef.current ? (
                <img src={dateRightNo} alt={dateRightNo} />
              ) : (
                <img
                  onClick={() => dateMonthClick(+1)}
                  src={dateRight}
                  alt={dateRight}
                />
              )}
            </h3>
          )}
          <p>{challengePartiDay}일째 챌린지 참여 중이에요!</p>
        </div>
        <article
          className={`${styles.chartWrap} ${!isMobile ? styles.isDesktop : ''}`}
        >
          <div
            className={`${styles.view} ${
              nowMonth > 6 && nowMonth < 13 ? styles.viewRight : styles.view
            }`}
          >
            {isMobile && (
              <Chart
                width={
                  tabMenuSelect === '주별'
                    ? weeklyData.length === 5
                      ? `${weeklyData.length * 58 + 100}px`
                      : `${weeklyData.length * 72 + 100}px`
                    : `${monthlyData.length * 58 + 100}px`
                }
                height={'220px'}
                options={{
                  chart: {
                    type: 'line',
                    zoom: {
                      enabled: false,
                    },
                    background: '#F6F7F9',

                    toolbar: {
                      show: false,
                    },
                  },
                  stroke: {
                    show: true,
                    curve: 'straight',
                    lineCap: 'butt',
                    colors: ['#131C31'],
                    width: 1,
                    dashArray: 0,
                  },

                  dataLabels: {
                    enabled: true,
                    textAnchor: 'middle',
                    distributed: true,
                    offsetX: 0,
                    offsetY: -8,
                    style: {
                      colors: ['transparent'],
                      fontFamily: 'Pretendard',
                      fontSize: '15px',
                      fontWeight: '500',
                    },
                    background: {
                      enabled: true,
                      foreColor: '#131C31',
                      padding: 4,
                      borderWidth: 0,
                      opacity: 1,
                    },
                  },
                  grid: {
                    borderColor: '#E5E5E5',
                    xaxis: {
                      lines: {
                        show: true,
                      },
                    },
                    yaxis: {
                      lines: {
                        show: false,
                      },
                    },
                    row: {
                      colors: ['transparent'],
                    },
                    padding: {
                      left: 49,
                      right: 49,
                    },
                  },
                  yaxis: {
                    show: false,
                  },
                  xaxis: {
                    position: 'bottom',
                    categories:
                      tabMenuSelect === '주별'
                        ? weeklyData &&
                          weeklyData.map((val, idx) => idx + 1 + '주')
                        : monthlyData &&
                          monthlyData.map((val) => Number(val.month) + '월'),
                    axisTicks: {
                      show: false,
                    },
                    axisBorder: {
                      show: false,
                    },
                    crosshairs: {
                      show: false,
                    },
                    labels: {
                      style: {
                        cssClass: styles.xAxisLabel,
                      },
                      hideOverlappingLabels: true,
                    },
                  },
                  markers: {
                    size: 4,
                    colors: ['#F6F7F9'],
                    strokeColors: '#131C31',
                    strokeWidth: 1,
                    strokeOpacity: 1,
                    strokeDashArray: 0,
                    fillOpacity: 1,
                    discrete: [],
                    shape: 'circle',
                    radius: 2,
                  },
                  tooltip: {
                    enabled: false,
                  },
                }}
                series={[
                  //차트에 사용되는 값
                  tabMenuSelect === '주별'
                    ? {
                        data:
                          weeklyData && weeklyData.map((val) => val.content),
                      }
                    : {
                        data:
                          monthlyData && monthlyData.map((val) => val.content),
                      },
                ]}
                type="line"
              />
            )}
            {isDesktop && (
              <Chart
                width={'100%'}
                height={'220px'}
                options={{
                  chart: {
                    type: 'line',
                    zoom: {
                      enabled: false,
                    },
                    background: '#F6F7F9',

                    toolbar: {
                      show: false,
                    },
                  },
                  stroke: {
                    show: true,
                    curve: 'straight',
                    lineCap: 'butt',
                    colors: ['#131C31'],
                    width: 1,
                    dashArray: 0,
                  },

                  dataLabels: {
                    enabled: true,
                    textAnchor: 'middle',
                    distributed: true,
                    offsetX: 0,
                    offsetY: -8,
                    style: {
                      colors: ['transparent'],
                      fontFamily: 'Pretendard',
                      fontSize: '15px',
                      fontWeight: '500',
                    },
                    background: {
                      enabled: true,
                      foreColor: '#131C31',
                      padding: 4,
                      borderWidth: 0,
                      opacity: 1,
                    },
                  },
                  grid: {
                    borderColor: '#E5E5E5',
                    xaxis: {
                      lines: {
                        show: true,
                      },
                    },
                    yaxis: {
                      lines: {
                        show: false,
                      },
                    },
                    row: {
                      colors: ['transparent'],
                    },
                    padding: {
                      left: 49,
                      right: 49,
                    },
                  },
                  yaxis: {
                    show: false,
                  },
                  xaxis: {
                    position: 'bottom',
                    categories:
                      tabMenuSelect === '주별'
                        ? weeklyData &&
                          weeklyData.map((val, idx) => idx + 1 + '주')
                        : monthlyData &&
                          monthlyData.map((val) => Number(val.month) + '월'),
                    axisTicks: {
                      show: false,
                    },
                    axisBorder: {
                      show: false,
                    },
                    crosshairs: {
                      show: false,
                    },
                    labels: {
                      style: {
                        cssClass: styles.xAxisLabel,
                      },
                      hideOverlappingLabels: true,
                    },
                  },
                  markers: {
                    size: 4,
                    colors: ['#F6F7F9'],
                    strokeColors: '#131C31',
                    strokeWidth: 1,
                    strokeOpacity: 1,
                    strokeDashArray: 0,
                    fillOpacity: 1,
                    discrete: [],
                    shape: 'circle',
                    radius: 2,
                  },
                  tooltip: {
                    enabled: false,
                  },
                }}
                series={[
                  {
                    data:
                      tabMenuSelect === '주별'
                        ? weeklyData &&
                          weeklyData.map((val) =>
                            val.content ? val.content : null,
                          )
                        : monthlyData &&
                          monthlyData.map((val) =>
                            val.content ? val.content : null,
                          ),
                  },
                ]}
                type="line"
              />
            )}
          </div>
        </article>
        {isMobile && <div className={styles.mHeight}></div>}
        <div
          className={`${styles.weightWrap} ${
            !isMobile ? styles.isDesktop : styles.isMobile
          }`}
        >
          <div className={styles.weightInfo}>
            <div>
              <p>평균 체중</p>
              {tabMenuSelect === '주별' && <p>{Number(avgWeekWeight)}kg</p>}
              {tabMenuSelect === '월별' && <p>{Number(avgMonthWeight)}kg</p>}
            </div>
            <div></div>
            <div>
              <p>총 감량 체중</p>
              {tabMenuSelect === '주별' && <p>{loseWeightWeek}kg</p>}
              {tabMenuSelect === '월별' && <p>{loseWeightMonth}kg</p>}
            </div>
          </div>
          <div className={styles.weightBtnWrap}>
            <button onClick={() => navigate('/myWeight/list')}>
              <img src={list} alt={list} />
              <span>리스트보기</span>
            </button>
            <button onClick={() => navigate('/myWeight/point')}>
              <img className={styles.pointImg} src={pointImg} alt={pointImg} />
              <span>리워드보기</span>
            </button>
          </div>
        </div>
      </main>
    </>
  );
}
export default WeightChart;
