import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import bannerImage from '../../assets/bannerImage01.jpeg';
import styles from './CompanyInfo.module.scss';
function CompanyInfo() {
  return (
    <div>
      <Header />
      <div className={styles.contentWrap}>
        <img src={bannerImage} alt="image" />
        <div className={styles.textWrap}>
          <h3>온닥은 쉽고 편리한 진료 서비스를 제공합니다.</h3>
          <p>
            온닥은 내원없이 간편하게 받아보는 비대면 진료서비스로 온닥의
            의료진과 함께 가벼운 증상부터 만성질환까지 의료 서비스 이용에
            어려움을 겪는 분들에게 빠르고 안전한, 쉽고 편리한 진료 서비스를
            제공합니다.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default CompanyInfo;
