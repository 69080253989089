import { useEffect, useState } from 'react';
import styles from './GambiteaModal.module.scss';
import axiosClient from '../../libs/axiosClient';
import { IProductDetailType } from '../../pages/ProductDetail/ProductDetail';

interface IPropsType {
  closer: React.Dispatch<React.SetStateAction<boolean>>;
}
function GambiteaModal(props: IPropsType) {
  const [gambiteaData, setGambiteaData] = useState<IProductDetailType>();
  useEffect(() => {
    axiosClient
      .get('/data/product/detail/81')
      .then((res) => setGambiteaData(res.data.data));
  }, []);
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  return (
    <div className={styles.gambiteaModal}>
      <div className={styles.gambiteaContent}>
        <span className={styles.closeBtn} onClick={() => props.closer(false)}>
          닫기
        </span>
        <div
          className={styles.detailImages}
          dangerouslySetInnerHTML={{
            __html: gambiteaData
              ? gambiteaData.content.replace(/\\/gi, '')
              : '',
          }}
        ></div>
      </div>
    </div>
  );
}
export default GambiteaModal;
