import styles from './MainReviewFrame.module.scss';
import star from '../../assets/star.png';
import emptyStar from '../../assets/emptyStar.png';
import { IReviewDataType } from '../../pages/MyReview/MyReview';
import { useNavigate } from 'react-router-dom';
import { isDesktop } from 'react-device-detect';
function MainReviewFrame(props: { review: IReviewDataType }) {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.reviewImageWrap}>
        <img
          src={
            props.review.images[0]
              ? props.review.images[0].image + '?w=400'
              : props.review.product.list_image + '?w=400'
          }
          alt="reviewImg"
        />
      </div>
      <div className={styles.reviewInfoWrap}>
        <div className={styles.reviewInfoTop}>
          <div className={styles.left}>
            <div className={styles.userIdScoreWrap}>
              <span className={styles.userId}>{props.review.member_id}님</span>
              <div className={styles.score}>
                <img src={star} alt="star" />
                <span>
                  {Math.round(
                    (props.review.stars0 +
                      props.review.stars1 +
                      props.review.stars2 +
                      props.review.stars3) /
                      4 /
                      20,
                  )}
                </span>
              </div>
            </div>
            <span className={styles.userInfo}>
              {props.review.age}대 /{' '}
              {props.review.gender == 'F' ? '여성' : '남성'}
              {props.review.weight > 0 &&
                `/ -
              ${props.review.weight}KG`}
            </span>
            {/* <div className={styles.reviewScore}>
              {(() => {
                let list: boolean[] = [];
                for (let i = 1; i <= 5; i++) {
                  if (
                    i <=
                    Math.round(
                      (props.review.stars0 +
                        props.review.stars1 +
                        props.review.stars2 +
                        props.review.stars3) /
                        4 /
                        20,
                    )
                  )
                    list.push(true);
                  else list.push(false);
                }
                <img src={star} alt="star" />;
                return list.map((isTrue, idx) => {
                  if (isTrue) return <img src={star} alt="star" key={idx} />;
                  else return <img src={emptyStar} alt="star" key={idx} />;
                });
              })()}
            </div> */}
          </div>
          <div
            className={`${styles.right} ${isDesktop ? styles.isDesktop : ''}`}
          >
            <img
              src={props.review.product.list_image + '?w=200'}
              alt="productThumbnail"
              // onClick={() =>
              //   navigate(`/productDetail/${props.review.product.product_id}`)
              // }
            />
          </div>
        </div>
        <p className={styles.reviewInfoBottom}>{props.review.content}</p>
      </div>
    </div>
  );
}
export default MainReviewFrame;
