import styles from './ChannelAlert.module.scss';
import channelAlert from '../../assets/channelAlert.png';
import { useEffect } from 'react';

function ChannelAlert() {
  return (
    <div className={styles.container}>
      <div className={styles.contentWrap}>
        <img src={channelAlert} alt="alert" />
      </div>
    </div>
  );
}

export default ChannelAlert;
