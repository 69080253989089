import styles from './ProductList.module.scss';
import Header from '../../components/Header/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import product01 from '../../assets/imsi/imsiProduct1.png';
import Footer from '../../components/Footer/Footer';
import { useQuery } from 'react-query';
import axiosClient from '../../libs/axiosClient';
import { isMobile } from 'react-device-detect';
import rightArrow from '../../assets/rightArrow.png';

export interface IProductListType {
  is_prescription: number; //0: 건강식품, 1:처방의약품
  blind_code: string;
  id: string;
  product_id: number;
  category_id: number;
  list_image: string;
  image: string;
  detail_url: string;
  discount_rate: number;
  title: string;
  price: number;
  soldout: string;
  discount_price: number;
  brand_name: string | null;
  hashtag: string;
  hospital_name: string | null;
  review_score_avg: {
    review_point_0_avg: number;
    review_point_1_avg: number;
    review_point_2_avg: number;
    review_point_3_avg: number;
    review_point_total_avg: number;
    review_total_count: number;
  };
  is_like: number;
}

function ProductList() {
  const location = useLocation();
  const [category, setCategory] = useState('');
  const navigate = useNavigate();
  const { data: productList } = useQuery<IProductListType[]>(
    ['productList', 'all'],
    async () => {
      let queryString = '';
      let ids = JSON.parse(
        sessionStorage.getItem('product_ids') || JSON.stringify([]),
      );
      if (ids.length) {
        queryString = 'add_products=';
        ids.map((id: number, idx: number) => {
          queryString += `${id}${idx !== ids.length - 1 ? ',' : ''}`;
        });
      }
      return await axiosClient
        .get(`/data/product/list?${queryString}`)
        .then((res) => res.data.data);
    },
    {
      staleTime: 60000 * 60 * 24,
    },
  );
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (!params.get('cuid')) setCategory('건강식품');
    // else if (params.get('cuid') == '487') setCategory('처방의약품');
    // else if (params.get('cuid') == '488') setCategory('건강식품');
  }, [location]);
  return (
    <div>
      <Header />
      <div className={styles.categorySelectWrap}>
        <h3>{category}</h3>
        {/* <ul className={styles.categoryList}>
          <li
            className={category == '병원진료' ? styles.hit : ''}
            onClick={() => navigate('/productList')}
          >
            병원진료
          </li>
          <li
            className={category == '처방의약품' ? styles.hit : ''}
            onClick={() => navigate('/productList?cuid=487')}
          >
            처방의약품
          </li>
          <li
            className={category == '건강식품' ? styles.hit : ''}
            onClick={() => navigate('/productList?cuid=488')}
          >
            건강식품
          </li>
        </ul> */}
        <ul className={styles.productList}>
          {productList &&
            productList
              .filter((product) => {
                // if (category == '병원진료') return true;
                // else if (category == '처방의약품')
                //   return product.is_prescription == 1;
                if (category == '건강식품') return product.is_prescription == 0;
              })
              .map((product) => (
                <li
                  key={product.product_id}
                  onClick={() =>
                    navigate(`/productDetail/${product.product_id}`)
                  }
                >
                  <div className={styles.thumbnailWrap}>
                    <img src={product.list_image} alt="thumbnail" />
                  </div>
                  {/* <span className={styles.hospitalName}>
                    {product.hospital_name ? product.hospital_name : ''}
                  </span> */}
                  <span className={styles.productName}>{product.title}</span>
                  <ul className={styles.hashTagList}>
                    {product.hashtag.split(',').map((hashtag, idx) => (
                      <li key={idx}>{hashtag}</li>
                    ))}
                  </ul>
                  <div className={styles.priceInfoWrap}>
                    <span className={styles.price}>
                      {product.price !== 0 && product.price.toLocaleString()}
                    </span>
                    <span className={styles.discountPrice}>
                      {product.discount_price.toLocaleString()}원
                    </span>
                  </div>
                </li>
              ))}
        </ul>
      </div>
      <Footer />
    </div>
  );
}
export default ProductList;
