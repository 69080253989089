import { IFaqType } from '../../pages/Faq/Faq';
import styles from './MainFaqFrameMobile.module.scss';
import minus from '../../assets/faqminus.png';
import plus from '../../assets/faqplus.png';
import { useState } from 'react';
function MainFaqFrameMobile(props: { faq: IFaqType }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={styles.container}>
      <div
        className={`${styles.askWrap} ${isOpen ? styles.isOpen : ''}`}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <img
          src={!isOpen ? plus : minus}
          alt="bottomArrow"
          style={{ transform: isOpen ? 'rotate(180deg)' : '' }}
        />
        <span>{props.faq.title}</span>
      </div>
      <div
        className={`${styles.answerWrap} ${isOpen ? styles.isOpen : ''}`}
        dangerouslySetInnerHTML={{ __html: props.faq.content }}
      ></div>
    </div>
  );
}
export default MainFaqFrameMobile;
