//해당 컴포넌트는 특정 상품 페이지에서 해당 상품에 연결된 광고 상품이 있을 시 사용되는 배너 컴포넌트 입니다.
import styles from './ADLink.module.scss';
import close from '../../assets/closeBtn03.png';
import content from '../../assets/adLink/content04.png';
import content2 from '../../assets/adLink/content05.png';
import productThumbnail from '../../assets/adLink/product.png';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkRequired } from '../../common/checkRequired';
interface IPropsType {
  productId: number;
  price: number;
  discountedPrice: number;
  closer: React.Dispatch<React.SetStateAction<boolean>>;
}
function ADLink({ productId, price, discountedPrice, closer }: IPropsType) {
  const navigate = useNavigate();
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const [idx, setIdx] = useState(0);
  useEffect(() => {
    if (!checkRequired('2023-07-17T00:00:00')) return;
    setIdx(1);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <button className={styles.closeBtn} onClick={() => closer(false)}>
          <img src={close} alt="닫기" />
        </button>
        {idx == 0 && (
          <div className={styles.contentBox}>
            <img src={productThumbnail} alt="상품썸네일" />
            <div className={styles.priceWrap}>
              <div className={styles.price}>
                <span>정상가</span>
                <span> : </span>
                <span>{price?.toLocaleString()}원</span>
              </div>
              <div className={styles.discountedPrice}>
                <span>할인가</span>
                <span> : </span>
                <span>{discountedPrice?.toLocaleString()}원</span>
              </div>
            </div>
            <h2>청담보감 다이어트 타블렛</h2>
            <button
              onClick={() =>
                (window.location.href = `https://ondoc.co.kr/productDetail/${productId}`)
              }
            >
              자세히 보기
            </button>
          </div>
        )}
        {idx == 1 && (
          <div
            className={styles.newContentBox}
            onClick={() =>
              (window.location.href = `https://ondoc.co.kr/productDetail/${productId}`)
            }
          >
            {productId === 94 ? (
              <img src={content2} alt="이벤트" />
            ) : (
              <img src={content} alt="이벤트" />
            )}

            <div className={styles.submitBtn}></div>
          </div>
        )}
      </div>
    </div>
  );
}
export default ADLink;
