import styles from './Review.module.scss';
import star from '../../assets/star.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SwiperCore from 'swiper';
import rightArrow from '../../assets/rightArrow.png';
import leftArrow02 from '../../assets/leftArrow02.png';
import rightArrow02 from '../../assets/rightArrow02.png';
import { useEffect, useState } from 'react';
import PhotoReviewFrame from '../PhotoReviewFrame/PhotoReviewFrame';
import NormalReviewFrame from '../NormalReviewFrame/NormalReviewFrame';
import { isDesktop } from 'react-device-detect';
import WriteReviewModal from '../WriteReviewModal/WriteReviewModal';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { loginCheck } from '../../auth/auth';
import ReviewFilter from '../ReviewFilter/ReviewFilter';
import ReviewDetailModal from '../ReviewDetailModal/ReviewDetailModal';
import axiosClient from '../../libs/axiosClient';
import { IReviewDataType, IReviewYetType } from '../../pages/MyReview/MyReview';
import { loginConfirm } from '../../common/loginConfirm';
import { IProductDetailType } from '../../pages/ProductDetail/ProductDetail';
import NoOrderReviewModal from '../ReviewModals/NoOrderReviewModal/NoOrderReviewModal';
import { useQuery } from 'react-query';
interface IPropsType {
  type?: string;
  allReviewData?: {
    product_id: number;
    review_point_0_avg: number;
    review_point_1_avg: number;
    review_point_2_avg: number;
    review_point_3_avg: number;
    review_point_total_avg: number;
    review_total_count: number;
  };
  firstReviewData: IReviewDataType[];
  firstReviewCount: number;
  firstPhotoReviewData: IReviewDataType[];
  firstPhotoReviewCount: number;
  product: IProductDetailType | undefined;
}
function Review(props: IPropsType) {
  const params = useParams();
  const navigate = useNavigate();
  const [reviewDetailOpen, setReviewDetailOpen] = useState(false);
  const [reviewDetailData, setReviewDetailData] = useState<IReviewDataType>();
  //페이지네이션
  const [reviewCount, setReviewCount] = useState(0);
  const [photoReviewCount, setPhotoReviewCount] = useState(0);
  const [paginationList, setPaginationList] = useState([1, 2, 3, 4, 5]);
  const [pagination, setPagination] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  function getReview() {
    setIsLoading(true);
    axiosClient
      .get(
        `/data/product_talk/list?&talk_type=상품평가&offset=${
          (pagination - 1) * 3
        }&limit=3&product_id=${params.productId}&order_by=star`,
      )
      .then((res) => {
        setReviewData(res.data.data);
        setReviewCount(res.data.total_count);
        setIsLoading(false);
      });
  }
  useEffect(() => {
    if (reviewData.length) getReview();
    else {
      //첫 렌더링시에만 상위 컴포넌트에서 props로 리뷰데이터 받아 오고 이후엔 if문 걸쳐서 컴포넌트 내부에서 페이지네이션에 맞는 리뷰데이터 조회
      setReviewData(props.firstReviewData);
      setReviewCount(props.firstReviewCount);
    }
  }, [pagination, props.firstReviewData]);
  function handlePaginationDown() {
    if (pagination == 1) return;
    setPagination((prev) => prev - 1);
  }
  function handlePaginationUp() {
    if (pagination >= reviewCount / 3) return;
    setPagination((prev) => prev + 1);
  }
  useEffect(() => {
    let list: number[] = [];
    let startPage = parseInt(String(pagination / 5)) * 5 + 1;
    if (!(pagination % 5)) startPage -= 5;
    let endPage =
      startPage + 4 < reviewCount / 3 + (reviewCount % 3 !== 0 ? 1 : 0)
        ? startPage + 4
        : reviewCount / 3 + (reviewCount % 3 !== 0 ? 1 : 0);
    for (let i = startPage; i <= endPage; i++) list.push(i);
    if (!list.length) list.push(1);
    setPaginationList([...list]);
  }, [reviewCount, pagination]);
  //페이지네이션

  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [swiper, setSwiper] = useState<SwiperCore>();
  useEffect(() => {
    if (!reviewDetailOpen && !reviewModalOpen) return;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [reviewDetailOpen, reviewModalOpen]);

  function handleReviewModalOpen() {
    setReviewModalOpen(true);
  }
  const [reviewData, setReviewData] = useState<IReviewDataType[]>([]);
  const [photoReviewData, setPhotoReviewData] = useState<IReviewDataType[]>([]);
  useEffect(() => {
    if (!photoReviewData.length) {
      //처음 데이터는 상위 컴포넌트에서 받아옴
      setPhotoReviewData(props.firstPhotoReviewData);
      setPhotoReviewCount(props.firstPhotoReviewCount);
    } else {
      axiosClient
        .get(
          `/data/product_talk/photo-review-list?&talk_type=상품평가&offset=${
            (pagination - 1) * 3
          }&limit=15&product_id=${params.productId}&order_by=order`,
        )
        .then((res) => {
          setPhotoReviewData(res.data.data);
          setPhotoReviewCount(res.data.total_count);
        });
    }
  }, [props.firstPhotoReviewData]);

  const { data: isWriteAble } = useQuery<IReviewYetType[]>(
    ['isWriteAble'],
    () =>
      axiosClient
        .get('/data/product_talk/list/review_yet')
        .then((res) => res.data.data),
    { refetchOnWindowFocus: false },
  );

  return (
    <div>
      <div
        className={`${styles.totalScoreWrap} ${
          !isDesktop ? styles.isMobile : ''
        }`}
      >
        <span className={styles.title}>
          전체 리뷰 (
          {props.allReviewData &&
            props.allReviewData.review_total_count.toLocaleString()}
          )
          <div
            onClick={() => {
              if (!loginConfirm()) return;
              handleReviewModalOpen();
            }}
          >
            리뷰 작성하기
          </div>
        </span>
        {isDesktop ? (
          <div className={styles.avgWrap}>
            <div className={styles.left}>
              <span>리뷰평점</span>
              <div>
                <img src={star} alt="star" />
                <span>
                  {props.allReviewData
                    ? Math.floor(
                        (props.allReviewData.review_point_total_avg / 2) * 10,
                      ) / 10
                    : 0}
                </span>
              </div>
            </div>
            <div className={styles.right}>
              <ul>
                <li>
                  <span className={styles.title}>👍🏻 효과가 좋아요</span>
                  <span className={styles.selectedPercent}>
                    {props.allReviewData
                      ? Math.floor(props.allReviewData.review_point_0_avg * 10)
                      : 0}
                    %
                  </span>
                  <div
                    className={styles.gauge}
                    style={{
                      backgroundColor: `rgba(255, 211, 60, ${
                        props.allReviewData
                          ? Math.floor(
                              props.allReviewData.review_point_0_avg * 10,
                            ) / 100
                          : 0
                      })`,
                      width: `${
                        props.allReviewData
                          ? Math.floor(
                              props.allReviewData.review_point_0_avg * 10,
                            )
                          : 0
                      }%`,
                    }}
                  ></div>
                </li>
                <li>
                  <span className={styles.title}>😋 맛과 향이 좋아요</span>
                  <span className={styles.selectedPercent}>
                    {props.allReviewData
                      ? Math.floor(props.allReviewData.review_point_1_avg * 10)
                      : 0}
                    %
                  </span>
                  <div
                    className={styles.gauge}
                    style={{
                      backgroundColor: `rgba(255, 211, 60, ${
                        props.allReviewData
                          ? Math.floor(
                              props.allReviewData.review_point_1_avg * 10,
                            ) / 100
                          : 0
                      })`,
                      width: `${
                        props.allReviewData
                          ? Math.floor(
                              props.allReviewData.review_point_1_avg * 10,
                            )
                          : 0
                      }%`,
                    }}
                  ></div>
                </li>
                <li>
                  <span className={styles.title}>👌🏻 가격대비 만족해요</span>
                  <span className={styles.selectedPercent}>
                    {props.allReviewData
                      ? Math.floor(props.allReviewData.review_point_2_avg * 10)
                      : 0}
                    %
                  </span>
                  <div
                    className={styles.gauge}
                    style={{
                      backgroundColor: `rgba(255, 211, 60, ${
                        props.allReviewData
                          ? Math.floor(
                              props.allReviewData.review_point_2_avg * 10,
                            ) / 100
                          : 0
                      })`,
                      width: `${
                        props.allReviewData
                          ? Math.floor(
                              props.allReviewData.review_point_2_avg * 10,
                            )
                          : 0
                      }%`,
                    }}
                  ></div>
                </li>
                <li>
                  <span className={styles.title}>🍽 먹기가 간편해요</span>
                  <span className={styles.selectedPercent}>
                    {props.allReviewData
                      ? Math.floor(props.allReviewData.review_point_3_avg * 10)
                      : 0}
                    %
                  </span>
                  <div
                    className={styles.gauge}
                    style={{
                      backgroundColor: `rgba(255, 211, 60, ${
                        props.allReviewData
                          ? Math.floor(
                              props.allReviewData.review_point_3_avg * 10,
                            ) / 100
                          : 0
                      })`,
                      width: `${
                        props.allReviewData
                          ? Math.floor(
                              props.allReviewData.review_point_3_avg * 10,
                            )
                          : 0
                      }%`,
                    }}
                  ></div>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div className={styles.avgWrapMobile}>
            <div className={styles.scoreWrap}>
              <div>
                <span>리뷰평점</span>
                <span>
                  (
                  {props.allReviewData &&
                    props.allReviewData.review_total_count.toLocaleString()}
                  )
                </span>
              </div>
              <div>
                <img src={star} alt="star" />
                <span>
                  {props.allReviewData
                    ? Math.floor(
                        (props.allReviewData.review_point_total_avg / 2) * 10,
                      ) / 10
                    : 0}
                </span>
              </div>
            </div>
            <div className={styles.simpleReviewWrap}>
              <div>
                <span>👍🏻 효과가 좋아요</span>
                <span>
                  {props.allReviewData
                    ? Math.floor(props.allReviewData.review_point_0_avg * 10)
                    : 0}
                  %
                </span>
                <div
                  className={styles.guage}
                  style={{
                    backgroundColor: `rgba(255, 211, 60, ${
                      props.allReviewData
                        ? Math.floor(
                            props.allReviewData.review_point_0_avg * 10,
                          ) / 100
                        : 0
                    })`,
                    width: `${
                      props.allReviewData
                        ? Math.floor(
                            props.allReviewData.review_point_0_avg * 10,
                          )
                        : 0
                    }%`,
                  }}
                ></div>
              </div>
              <div>
                <span>😋 맛과 향이 좋아요</span>
                <span>
                  {props.allReviewData
                    ? Math.floor(props.allReviewData.review_point_1_avg * 10)
                    : 0}
                  %
                </span>
                <div
                  className={styles.guage}
                  style={{
                    backgroundColor: `rgba(255, 211, 60, ${
                      props.allReviewData
                        ? Math.floor(
                            props.allReviewData.review_point_1_avg * 10,
                          ) / 100
                        : 0
                    })`,
                    width: `${
                      props.allReviewData
                        ? Math.floor(
                            props.allReviewData.review_point_1_avg * 10,
                          )
                        : 0
                    }%`,
                  }}
                ></div>
              </div>
              <div>
                <span>👌🏻 가격대비 만족해요</span>
                <span>
                  {props.allReviewData
                    ? Math.floor(props.allReviewData.review_point_2_avg * 10)
                    : 0}
                  %
                </span>
                <div
                  className={styles.guage}
                  style={{
                    backgroundColor: `rgba(255, 211, 60, ${
                      props.allReviewData
                        ? Math.floor(
                            props.allReviewData.review_point_2_avg * 10,
                          ) / 100
                        : 0
                    })`,
                    width: `${
                      props.allReviewData
                        ? Math.floor(
                            props.allReviewData.review_point_2_avg * 10,
                          )
                        : 0
                    }%`,
                  }}
                ></div>
              </div>
              <div>
                <span>🍽 먹기가 간편해요</span>
                <span>
                  {props.allReviewData
                    ? Math.floor(props.allReviewData.review_point_3_avg * 10)
                    : 0}
                  %
                </span>
                <div
                  className={styles.guage}
                  style={{
                    backgroundColor: `rgba(255, 211, 60, ${
                      props.allReviewData
                        ? Math.floor(
                            props.allReviewData.review_point_3_avg * 10,
                          ) / 100
                        : 0
                    })`,
                    width: `${
                      props.allReviewData
                        ? Math.floor(
                            props.allReviewData.review_point_3_avg * 10,
                          )
                        : 0
                    }%`,
                  }}
                ></div>
              </div>
            </div>
          </div>
        )}
      </div>
      {props.type == 'first' ? (
        <div
          className={`${styles.photoReview} ${
            !isDesktop ? styles.isMobile : ''
          }`}
        >
          <span className={styles.title}>포토 리뷰 ({photoReviewCount})</span>
          <div className={styles.photoReviewSlider}>
            {!loginCheck() &&
              props.product?.is_prescription !== 0 &&
              photoReviewData.length > 0 && <ReviewFilter />}
            <Swiper
              slidesPerView={1.4}
              spaceBetween={10}
              onSwiper={setSwiper}
              breakpoints={{ 700: { slidesPerView: 2.8 } }}
            >
              {photoReviewData.map((review) => (
                <SwiperSlide
                  onClick={() => {
                    setReviewDetailOpen(true);
                    setReviewDetailData(review);
                  }}
                  key={review.id}
                >
                  <PhotoReviewFrame review={review} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      ) : (
        <div className={styles.photoReviewSecond}>
          <span>포토리뷰 ({photoReviewCount})</span>
          <ul className={styles.photoList}>
            {!loginCheck() &&
              props.product?.is_prescription !== 0 &&
              photoReviewData.length > 0 && <ReviewFilter />}
            {photoReviewData
              .filter((review, idx) => idx < 5)
              .map((review) => (
                <li
                  key={review.id}
                  onClick={() => {
                    setReviewDetailOpen(true);
                    setReviewDetailData(review);
                  }}
                >
                  <img
                    src={review.images[0].image + '?w=570'}
                    alt="reviewPhoto"
                  />
                  {review.images.length > 1 && (
                    <div className={styles.imageCount}>
                      +{review.images.length - 1}
                    </div>
                  )}
                </li>
              ))}
          </ul>
        </div>
      )}
      {props.type == 'first' ? (
        <div
          className={`${styles.normalReview} ${
            !isDesktop ? styles.isMobile : ''
          }`}
        >
          <div className={styles.head}>
            <span>
              전체 리뷰 (
              {props.allReviewData &&
                props.allReviewData.review_total_count.toLocaleString()}
              )
            </span>
            <div
              className={styles.moreBtn}
              onClick={() => navigate(`/review/${params.productId}`)}
            >
              <span>더보기</span>
              <img src={rightArrow} alt="arrow" />
            </div>
          </div>
          <ul className={styles.normalReviewList}>
            {!loginCheck() &&
              props.product?.is_prescription !== 0 &&
              photoReviewData.length > 0 && <ReviewFilter />}
            {reviewData?.map((review) => (
              <li key={review.id}>
                <NormalReviewFrame review={review} />
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div
          className={`${styles.normalReviewSecond} ${
            !isDesktop ? styles.isMobile : ''
          }`}
        >
          <span>
            리뷰 (
            {props.allReviewData &&
              props.allReviewData.review_total_count.toLocaleString()}
            )
          </span>
          <ul className={styles.normalReviewList}>
            {!loginCheck() &&
              props.product?.is_prescription !== 0 &&
              photoReviewData.length > 0 && <ReviewFilter />}
            {reviewData?.map((review) => (
              <li key={review.id}>
                <NormalReviewFrame review={review} />
              </li>
            ))}
          </ul>
        </div>
      )}
      {/* {!isDesktop && (
        <div className={styles.writeReviewBtnWrap}>
          <div
            className={styles.writeReviewBtn}
            onClick={() => {
              if (!loginConfirm()) return;
              handleReviewModalOpen();
            }}
          >
            리뷰작성
          </div>
        </div>
      )} */}
      <div className={styles.paginationAndBtn}>
        {props.type !== 'first' && (
          <div className={styles.pagination}>
            <img src={leftArrow02} alt="arrow" onClick={handlePaginationDown} />
            <div className={styles.number}>
              {paginationList.map((number) => (
                <span
                  key={number}
                  className={number == pagination ? styles.hit : ''}
                  onClick={() => setPagination(number)}
                >
                  {number}
                </span>
              ))}
            </div>
            <img src={rightArrow02} alt="arrow" onClick={handlePaginationUp} />
          </div>
        )}
        {/* {isDesktop && (
          <div
            className={styles.writeReviewBtn}
            onClick={() => {
              if (!loginConfirm()) return;
              handleReviewModalOpen();
            }}
          >
            리뷰작성
          </div>
        )} */}
      </div>
      {reviewDetailOpen && (
        <ReviewDetailModal
          closer={setReviewDetailOpen}
          imageList={reviewDetailData!.images}
          userId={reviewDetailData!.member_id}
          age={reviewDetailData!.age}
          gender={reviewDetailData!.gender}
          weight={reviewDetailData!.weight}
          score={
            reviewDetailData!.stars0 +
            reviewDetailData!.stars1 +
            reviewDetailData!.stars2 +
            reviewDetailData!.stars3
          }
          content={reviewDetailData!.content}
          productImage={reviewDetailData!.product.list_image}
        />
      )}
      {reviewModalOpen &&
        (isWriteAble?.length ? (
          <WriteReviewModal closer={setReviewModalOpen} />
        ) : (
          <NoOrderReviewModal
            closer={setReviewModalOpen}
            product={props.product}
          />
        ))}
    </div>
  );
}
export default Review;
