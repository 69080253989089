import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

function OrderConfirm() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const errorMessage = new URLSearchParams(location.search).get(
    'error_message',
  );
  useEffect(() => {
    if (params.result == 'close') {
      alert('결제를 취소하였습니다.');
      let url = sessionStorage.getItem('lastOrderPageUrl');
      if (url) navigate(url);
      else navigate('/');
    } else if (params.result == 'success') {
      sessionStorage.removeItem('temporaryOrderInfo');
      navigate(`/orderComplete/${params.orderNumber}`);
    } else if (params.result == 'fail') {
      if (errorMessage) alert(errorMessage);
      else
        alert(
          '결제를 실패했습니다. 동일 증상 반복시 고객센터로 문의 바랍니다.',
        );
      navigate('/');
    }
  }, []);
  return <div>orderConfirm</div>;
}
export default OrderConfirm;
