import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import MyPageNavigate from '../../components/MyPageNavigate/MyPageNavigate';
import styles from './MyCard.module.scss';
import plus from '../../assets/plus02.png';
import { isDesktop } from 'react-device-detect';
import leftArrow from '../../assets/leftArrow02.png';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axiosClient from '../../libs/axiosClient';
import LoginHandler from '../../components/LoginHandler/LoginHandler';

interface ICardType {
  card_member: '이주연';
  card_name: 'NH채움';
  card_num: '4854-****-****-1195';
  card_type: '개인';
}
function MyCard() {
  const navigate = useNavigate();
  let [card, setCard] = useState<ICardType>();
  useEffect(() => {
    axiosClient.get('/data/mypage/payple_card').then((res) => {
      if (res.data.data.status == 'success') setCard(res.data.data.data);
    });
  }, []);
  function handleDeleteCard() {
    let confirm = window.confirm('등록된 카드를 삭제하시겠습니까?');
    if (!confirm) return;
    axiosClient
      .delete('/data/mypage/payple_card')
      .then((res) => {
        alert('카드가 삭제되었습니다.');
        setCard(undefined);
      })
      .catch((err) => alert('에러가 발생하였습니다. 다시 시도해주세요.'));
  }
  return (
    <div>
      <Header />
      <LoginHandler />
      <div className={styles.contentWrap}>
        {isDesktop && <MyPageNavigate />}
        <h3 className={`${styles.title} ${!isDesktop ? styles.isMobile : ''}`}>
          {!isDesktop && (
            <img src={leftArrow} alt="leftArrow" onClick={() => navigate(-1)} />
          )}
          <span>간편 카드 등록</span>
        </h3>
        <div className={styles.myCardsWrap}>
          <h4>
            등록된카드 <span>{card ? 1 : 0}</span> 장
          </h4>
          {card && (
            <ul className={styles.cardList}>
              <li className={styles.card}>
                <span className={styles.cardDelete} onClick={handleDeleteCard}>
                  삭제
                </span>
                <div className={styles.cardInfo}>
                  <h5>{card.card_name}</h5>
                  <span>{card.card_num}</span>
                </div>
              </li>
            </ul>
          )}
          {/* <div className={styles.appendCardBtn}>
            <img src={plus} alt="plus" />
            <span>카드 등록</span>
          </div> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default MyCard;
