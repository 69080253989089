import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

function Guide() {
  return (
    <div>
      <Header />
      <div style={{ padding: "64px 0" }}>
        <h3
          style={{
            color: "#000",
            fontSize: "29px",
            letterSpacing: "-1px",
            textAlign: "center",
            fontWeight: "600",
          }}
        >
          이용안내
        </h3>
        <div
          dangerouslySetInnerHTML={{
            __html: `<div class="editor"><img src="/upfiles/smarteditor/20200424092016655959402.jpg" title=""><br style="clear:both;"><br><p style="font-size:15px; color:#000; letter-spacing:-0.5px; font-weight:600;">회원 가입 안내</p><br>
            <p>① 저희 쇼핑몰은 기본적으로 회원제로 운영하고 있습니다. 운영방침에 따라 비회원구매가 가능한 경우가 있습니다. <br>
            ② 회원 가입은 월회비, 연회비등 어떠한 비용도 청구하지 않으며 100% 무료로 진행됩니다. <br>
            ③ 회원 가입 시 가입 환영 축하금으로 <b>0</b><strong>원</strong>의 적립금이 지급됩니다.&nbsp;</p><p>
            ④ 구매 시 적립된 적립금은 <strong>1,000원</strong>부터 현금처럼 사용할 수 있습니다. <br>
            ⑤ 적립금 제도는 온라인으로 가입한 회원에게만 적용되는 멤버십 혜택입니다. </p><br>
            <p style="font-size:15px; color:#000; letter-spacing:-0.5px; font-weight:600;">적립금 제도</p><br>
            <p>① 저희 쇼핑몰은 상품에 따라 일정 비율로 적립금이 자동으로 적립됩니다.<br>
            ② 적립금 100원은 현금 100원과 같습니다. <br>
            ③ 적립금은 <b>1,000</b><strong>원</strong> 이상 되면 사용할 수 있고 한 주문 당 <b>10,000</b><strong>원</strong> 이 넘는 금액의 적립금은 사용할 수 없습니다.</p><br>
            <p style="font-size:15px; color:#000; letter-spacing:-0.5px; font-weight:600;">상품 주문 방법</p><br>
            <p>저희 쇼핑몰에서 상품을 주문하는 방법은 크게 7단계입니다. <br>
            ① 상품검색<br>
            ② 바로구매 혹은 장바구니에 담기<br>
            ③ 회원으로 로그인 후 주문 (정책에 따라 비회원 구매 가능)<br>
            ④ 주문서 작성 <br>
            ⑤ 결제방법 선택 및 결제 <br>
            ⑥ 주문 완료 (주문번호) <br>
            ⑦ 마이페이지 주문내역 확인 (배송확인)</p><br>
            <p style="font-size:15px; color:#000; letter-spacing:-0.5px; font-weight:600;">주문확인 및 배송조회</p><br>
            <p>쇼핑몰에서 주문을 하셨을 경우 마이페이지의 주문내역에서 주문상황을 바로 확인 하실 수 있습니다. <br>
            주문단계에 따라 주문취소/환불 등이 가능한 경우가 있고 불가능한 경우가 있으니 이에 대한 안내는 1:1온라인문의나 고객센터<!--치환자-->(070-7666-9628
)를 이용해주세요.<br>
            정책에 따라 비회원 구매가 가능한 경우에는 주문번호를 꼭 기억하고 계셔야 확인이 가능합니다.<br>
            현재 배송은 <b>CJ대한통운택배</b>&nbsp;택배 서비스를 이용하고 있습니다.&nbsp;</p><p>
            주문상태가 배송으로 바뀌면 택배 운송장번호로 택배사 홈페이지 배송추적을 통해 정확한 배송상태를 추적하실 수 있습니다.</p><br>
            <p style="font-size:15px; color:#000; letter-spacing:-0.5px; font-weight:600;">안전한 대금 결제 시스템</p><br>
            <p>
            저희 쇼핑몰은 안전한 전자 결제 서비스(PG)에 가입이 되어있으며, 가능한 결제 수단은 주문결제 단계에서 확인할 수 있습니다.<br>
            무통장 입금은 PC뱅킹, 인터넷뱅킹, 텔레뱅킹 혹은 가까운 은행에서 직접 입금하시면 되고, 신용카드 결제는 KG 이니시스 전자결제 서비스를 이용하므로 보안문제는 걱정하지 않으셔도 되며, 고객님의 이용내역서에는 KG 이니시스에 기록됩니다. <br><br>
    
            이용 가능한 국내 발행 신용카드 <br>
            - 국내발행 모든 신용카드 <br><br>
    
            이용 가능한 해외 발생 신용카드 <br>
            - VISA Card, MASTER Card, AMEX Card <br><br>
    
            무통장 입금 가능 은행 <br>
            - 주문시 무통장 입금을 선택할 경우 가능한 은행 목록을 확인 하실 수 있습니다.<br>
            - 무통장 입금시의 송금자 이름은 주문시 입력하신 주문자의 실명이어야 합니다. </p><br>
            <p style="font-size:15px; color:#000; letter-spacing:-0.5px; font-weight:600;">배송기간 및 배송방법</p><br>
            <p>무통장 입금의 경우는 입금하신 날로부터, 신용카드로 구매하신 경우에는 구매하신 날로부터 2-3일 이내에(최장 7일이내) 입력하신 배송처로 주문상품이 도착하게 됩니다. <br>
            주문하신 상품에 따라 배송기간이 조금 상이할 수 있으니 자세한 사항은 상품상세페이지에 명시되어있는 배송관련 내용을 참조해주세요.<br>
            현재 배송은 <b>CJ대한통운택배</b>&nbsp;택배 서비스를 이용하고 있습니다.</p><br>
            <p style="font-size:15px; color:#000; letter-spacing:-0.5px; font-weight:600;">주문취소, 교환 및 환불</p><br>
            <p>
            쇼핑몰은 소비자의보호를 위해서 규정한 제반 법규를 준수합니다. <br>
            주문 취소는 미결재인 상태에서는 고객님이 직접 취소하실 수가 있습니다. 결제후 취소는 저희 고객센터 070-7666-9628
            로 문의해 주시기 바랍니다. <br>
            무통장 입금의 경우 일정기간동안 송금을 하지 않으면 자동 주문 취소가 되고, 구매자가 원하는 경우 인터넷에서 바로 취소 하실 수도 있으며, 송금을 하신 경우에는 환불조치 해드립니다. <br>
            카드로 결제하신 경우, 승인 취소가 가능하면 취소을 해드리지만 승인 취소가 불가능한 경우 해당 금액을 모두 송금해 드립니다. <br>
            이때 승인취소는 카드사에 따라 2-3일 정도 소요될 수 있습니다.<br>
            반송을 하실 때에는 주문번호, 회원정보를 메모하여 보내주시면 보다 신속한 처리에 도움이 됩니다.<br>
            상품에 문제가 있거나 교환/환불을 원하는 경우 고객센터로 먼저 연락해주시고, 반송주소로 주문번호, 회원정보(이름,휴대폰 번호 등), 반품사유 등을 메모하여 반송해 주시면 상품 대금은 예치되거나 고객센터를 통해 환불조치 됩니다. (반송료는 고객 부담 입니다.)<br><br>
    
            반송주소 : 하단 카피라잇부분의 사업장소재지(주소)를 참고해 주십시오.</p>			</div>`,
          }}
        ></div>
      </div>
      <Footer />
    </div>
  );
}
export default Guide;
