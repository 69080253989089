import styles from './Navigation.module.scss';
import home from '../../assets/navigation/home.png';
import homeHit from '../../assets/navigation/homeHit.png';
import pill from '../../assets/navigation/pill.png';
import pillHit from '../../assets/navigation/pillHit.png';
import challenge from '../../assets/navigation/challenge.png';
import challengeHit from '../../assets/navigation/challengeHit.png';
import review from '../../assets/navigation/review.png';
import reviewHit from '../../assets/navigation/reviewHit.png';
import myPage from '../../assets/navigation/myPage.png';
import myPageHit from '../../assets/navigation/myPageHit.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { loginConfirmRurl } from '../../common/loginConfirm';
import { useEffect, useRef, useState } from 'react';
function Navigation() {
  const location = useLocation();
  const navigate = useNavigate();
  const [keyboardState, setKeyboardState] = useState(false);
  const [viewportHeight, setViewportHeight] = useState<any>(0);
  const [changeViewportHeight, setChangeViewportHeight] = useState<any>(0);
  const containerRef: any = useRef(0);
  let mobileApp: any;
  useEffect(() => {
    mobileApp = localStorage.getItem('mobileApp');
    if (!mobileApp) return;

    setViewportHeight(window.visualViewport?.height);
    window.addEventListener('resize', detectMobileKeyboard);
    return () => {
      window.removeEventListener('resize', detectMobileKeyboard);
    };
  }, []);

  const detectMobileKeyboard = () => {
    setChangeViewportHeight(window.visualViewport?.height);
  };

  useEffect(() => {
    if (!viewportHeight) {
      setViewportHeight(window.visualViewport?.height);
      setKeyboardState(false);
      return;
    }

    if (viewportHeight !== window.visualViewport?.height)
      setKeyboardState(true);
    else setKeyboardState(false);
  }, [changeViewportHeight]);

  const [isDetail, setIsDetail] = useState(false);

  useEffect(() => {
    if (window.location.pathname.includes('productDetail')) setIsDetail(true);
    else setIsDetail(false);
  }, [window.location.pathname]);
  return (
    <div
      style={{
        display: isDetail ? 'none' : 'block',
      }}
    >
      <div
        className={
          keyboardState === false ? styles.container : styles.keyboardContainer
        }
        ref={containerRef}
      >
        <ul className={styles.navibarUl}>
          <li
            className={location.pathname == '/' ? styles.hit : ''}
            onClick={() => navigate('/')}
          >
            <img src={location.pathname == '/' ? homeHit : home} alt="home" />
            <span>홈</span>
          </li>
          {/* <li
          className={
            location.pathname + location.search == '/productList?cuid=487'
              ? styles.hit
              : ''
          }
          onClick={() => navigate('/productList?cuid=487')}
        >
          <img
            src={
              location.pathname + location.search == '/productList?cuid=487'
                ? pillHit
                : pill
            }
            alt="pill"
          />
          <span>처방의약품</span>
        </li> */}
          <li
            className={
              location.pathname + location.search == '/program-list'
                ? styles.hit
                : ''
            }
            onClick={() => navigate('/program-list')}
          >
            <img
              src={
                location.pathname + location.search == '/program-list'
                  ? pillHit
                  : pill
              }
              alt="pill"
            />
            <span>병원진료</span>
          </li>
          <li
            className={
              location.pathname + location.search == '/myWeight'
                ? styles.hit
                : ''
            }
            onClick={() => {
              if (!loginConfirmRurl('/myWeight')) return;
              navigate('/myWeight');
            }}
          >
            <img
              src={
                location.pathname + location.search == '/myWeight'
                  ? challengeHit
                  : challenge
              }
              alt="pill"
            />
            <span>챌린지</span>
          </li>
          <li
            className={
              location.pathname.substring(0, 7) == '/review' ? styles.hit : ''
            }
            onClick={() => {
              if (!loginConfirmRurl('/review/all')) return;
              navigate('/review/all');
            }}
          >
            <img
              src={
                location.pathname.substring(0, 7) == '/review'
                  ? reviewHit
                  : review
              }
              alt="review"
            />
            <span>상품후기</span>
          </li>
          <li
            className={
              location.pathname.substring(0, 7) == '/myPage' ? styles.hit : ''
            }
            onClick={() => {
              if (!loginConfirmRurl('/myPage')) return;
              navigate('/myPage');
            }}
          >
            <img
              src={
                location.pathname.substring(0, 7) == '/myPage'
                  ? myPageHit
                  : myPage
              }
              alt="myPage"
            />
            <span>마이페이지</span>
          </li>
        </ul>
        <div className={styles.safeArea}></div>
      </div>
    </div>
  );
}
export default Navigation;
