import styles from './PhotoReviewFrame.module.scss';
import photoReview04 from '../../assets/imsi/imsiPhotoReview04.png';
import imsiProduct from '../../assets/imsi/imsiProduct4.png';
import star from '../../assets/star.png';
import emptyStar from '../../assets/emptyStar.png';
import { IReviewDataType } from '../../pages/MyReview/MyReview';
import { useState } from 'react';
import ReviewDetailModal from '../ReviewDetailModal/ReviewDetailModal';
import { useNavigate } from 'react-router-dom';
import { isDesktop } from 'react-device-detect';

interface IPropsType {
  type?: string;
  review: IReviewDataType;
}
//props로 type board 문자열 받을 시 상품 게시판쪽 디자인으로 수정
function PhotoReviewFrame(props: IPropsType) {
  const navigate = useNavigate();
  return (
    <>
      <div
        className={`${styles.container} ${
          props.type == 'board' ? styles.boardPageFrame : ''
        }`}
      >
        <img
          src={
            props.review.images
              ? props.review.images[0].image + '?w=570'
              : props.review.product.list_image + '?w=570'
          }
          alt="thumbnail"
        />
        <div className={styles.reviewInfoWrap}>
          <span className={styles.userId}>{props.review.member_id}</span>
          <span className={styles.userInfo}>
            {props.review.age}대 /{' '}
            {props.review.gender == 'F' ? '여성' : '남성'}
            {props.review.weight > 0 && (
              <>
                {isDesktop && <> / 감량체중 </>}-{props.review.weight}kg
              </>
            )}
          </span>
          <div className={styles.starWrap}>
            {(() => {
              let list: boolean[] = [];
              for (let i = 1; i <= 5; i++) {
                if (
                  i <=
                  Math.round(
                    (props.review.stars0 +
                      props.review.stars1 +
                      props.review.stars2 +
                      props.review.stars3) /
                      4 /
                      20,
                  )
                )
                  list.push(true);
                else list.push(false);
              }
              <img src={star} alt="star" />;
              return list.map((isTrue, idx) => {
                if (isTrue) return <img src={star} key={idx} alt="star" />;
                else return <img src={emptyStar} key={idx} alt="star" />;
              });
            })()}
          </div>
          <p className={styles.content}>{props.review.content}</p>
        </div>
        {props.type == 'board' && (
          <div
            className={styles.productInfoWrap}
            // onClick={() =>
            //   navigate(`/productDetail/${props.review.product.product_id}`)
            // }
          >
            <img
              src={props.review.product.list_image + '?w=200'}
              alt="thumbnail"
            />
            <div className={styles.productInfo}>
              <span>{props.review.product.title}</span>
              <div>
                <div className={styles.reviewAvg}>
                  <span>평점</span>
                  <span>
                    {Math.floor(
                      (props.review.product.review_score_avg
                        .review_point_total_avg /
                        2) *
                        10,
                    ) / 10}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default PhotoReviewFrame;
