import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './PageChangeAnimation.module.scss';
function PageChangeAnimation() {
  const location = useLocation();
  const [start, setStart] = useState(false);
  useEffect(() => {
    setStart(true);
  }, [location.pathname]);
  useEffect(() => {
    if (!start) return;
    setTimeout(() => {
      setStart(false);
    }, 800);
  }, [start]);

  return (
    <div className={styles.container}>
      {start && <div className={styles.shadow}></div>}
    </div>
  );
}
export default PageChangeAnimation;
