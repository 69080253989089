import { useEffect, useState } from 'react';
import { IReviewDataType } from '../../pages/MyReview/MyReview';
import styles from './MyReviewTab.module.scss';
import { isDesktop } from 'react-device-detect';
import axiosClient from '../../libs/axiosClient';
import WriteReviewModal from '../WriteReviewModal/WriteReviewModal';
import ReviewDetailModal from '../ReviewDetailModal/ReviewDetailModal';
import star from '../../assets/star.png';
import emptyStar from '../../assets/emptyStar.png';
import leftArrow from '../../assets/leftArrow02.png';
import PutReviewModal from '../ReviewModals/PutReviewModal/PutReviewModal';

function MyReviewTab() {
  const [myReviews, setMyReviews] = useState<IReviewDataType[]>([]);
  const [reviewCount, setReviewCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState(1);
  const [paginationNumber, setPaginationNumber] = useState<number[]>([1]);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [reviewModalTid, setReviewModalTid] = useState(0);
  const [reviewModalPid, setReviewModalPid] = useState(0);
  const [reviewModalOpid, setReviewModalOpid] = useState(0);
  const [reviewDetailOpen, setReviewDetailOpen] = useState(false);
  const [reviewDetailInfo, setReviewDetailInfo] = useState<IReviewDataType>();

  function getMyReview() {
    axiosClient
      .get(
        `/data/product_talk/list?talk_type=상품평가&is_mine=Y&offset=${
          (pagination - 1) * 3
        }&limit=3`,
      )
      .then((res) => {
        setMyReviews(res.data.data);
        setReviewCount(res.data.total_count);
        setIsLoading(false);
      });
  }
  useEffect(() => {
    setIsLoading(true);
    getMyReview();
  }, [pagination]);
  function handlePaginationDown() {
    if (pagination == 1) return;
    setPagination((prev) => prev - 1);
  }
  function handlePaginationUp() {
    if (pagination >= reviewCount / 3) return;
    setPagination((prev) => prev + 1);
  }
  const [selectedReview, setSelectedReview] = useState<IReviewDataType>();
  function handleDelete(id: number) {
    const confirm = window.confirm('리뷰를 삭제하시겠습니까?');
    if (!confirm) return;
    axiosClient.delete(`/func/product_talk?id=${id}`).then((res) => {
      axiosClient
        .get(
          `/data/product_talk/list?talk_type=상품평가&is_mine=Y&offset=${
            (pagination - 1) * 3
          }&limit=3`,
        )
        .then((res) => {
          setMyReviews(res.data.data);
          setReviewCount(res.data.total_count);
          setIsLoading(false);
        });
    });
  }
  useEffect(() => {
    let list: number[] = [];
    let startPage = parseInt(String(pagination / 5)) * 5 + 1;
    if (!(pagination % 5)) startPage -= 5;
    let endPage =
      startPage + 4 < reviewCount / 3 + (reviewCount % 3 !== 0 ? 1 : 0)
        ? startPage + 4
        : reviewCount / 3 + (reviewCount % 3 !== 0 ? 1 : 0);
    for (let i = startPage; i <= endPage; i++) list.push(i);
    if (!list.length) list.push(1);
    setPaginationNumber([...list]);
  }, [reviewCount, pagination]);
  return (
    <div>
      <ul className={`${styles.reviewList} ${!isDesktop ? styles.mobile : ''}`}>
        {myReviews.map((review) => (
          <li key={review.id}>
            <div className={styles.reviewBox}>
              <div>
                <div className={styles.reviewInfoWrap}>
                  <span className={styles.productName}>
                    [ {review.product.title} ]
                  </span>
                  <div className={styles.optionWrap}>
                    <span>옵션</span>
                    <span>{review.options}</span>
                  </div>
                  <div className={styles.reviewerInfoWrap}>
                    <div>
                      <span>성별</span>
                      <span>
                        {review.gender == 'F'
                          ? '여성'
                          : review.gender == 'M'
                          ? '남성'
                          : ''}
                      </span>
                    </div>
                    <div>
                      <span>연령대</span>
                      <span>
                        {review.age == 20
                          ? '20대'
                          : review.age == 30
                          ? '30대'
                          : review.age == 40
                          ? '40대'
                          : '50대 이상'}
                      </span>
                    </div>
                  </div>
                  <div className={styles.reviewScore}>
                    <span>별점 평가</span>
                    <div className={styles.starWrap}>
                      {(() => {
                        let totalScore = Math.round(
                          (review.stars0 +
                            review.stars1 +
                            review.stars2 +
                            review.stars3) /
                            4,
                        );
                        let scoreArray: boolean[] = [];
                        for (let i = 1; i <= 5; i++) {
                          if (i * 20 <= totalScore) scoreArray.push(true);
                          else scoreArray.push(false);
                        }
                        return scoreArray.map((isFull) => {
                          if (isFull) return <img src={star} alt={star} />;
                          else return <img src={emptyStar} alt={star} />;
                        });
                      })()}
                    </div>
                    <span>
                      {review.created_at.substring(2, 10).replace(/-/gi, '.')}
                    </span>
                  </div>
                </div>
                <div className={styles.right}>
                  <div className={styles.editBtns}>
                    <span
                      onClick={() => {
                        setReviewModalOpen(true);
                        setReviewModalPid(review.product_id);
                        setReviewModalOpid(0);
                        setReviewModalTid(review.id);
                        setSelectedReview(review);
                      }}
                    >
                      수정
                    </span>
                    <span
                      onClick={() => {
                        handleDelete(review.id);
                      }}
                    >
                      삭제
                    </span>
                  </div>
                  <div
                    className={styles.reviewImageWrap}
                    onClick={() => {
                      setReviewDetailOpen(true);
                      setReviewDetailInfo(review);
                    }}
                  >
                    <img
                      src={
                        review.images.length > 0
                          ? review.images[0].image
                          : review.product.list_image
                      }
                      alt="thumbnail"
                    />
                    {review.images.length > 1 && (
                      <div className={styles.reviewImageCounter}>
                        +{review.images.length - 1}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <p className={styles.reviewContent}>{review.content}</p>
            </div>
          </li>
        ))}
      </ul>
      <div className={styles.paginationWrap}>
        <img src={leftArrow} alt="leftArrow" onClick={handlePaginationDown} />
        <ul>
          {paginationNumber.map((number) => (
            <li
              key={number}
              className={number == pagination ? styles.hit : ''}
              onClick={() => setPagination(number)}
            >
              {number}
            </li>
          ))}
        </ul>
        <img
          src={leftArrow}
          alt="rightArrow"
          style={{ transform: 'rotate(180deg)' }}
          onClick={handlePaginationUp}
        />
      </div>
      {reviewModalOpen && (
        <PutReviewModal
          closer={setReviewModalOpen}
          reviewData={selectedReview!}
          refetch={getMyReview}
        />
      )}
      {reviewDetailOpen && (
        <ReviewDetailModal
          closer={setReviewDetailOpen}
          imageList={reviewDetailInfo!.images}
          userId={reviewDetailInfo!.member_id}
          age={reviewDetailInfo!.age || 0}
          gender={reviewDetailInfo!.gender}
          weight={reviewDetailInfo!.weight}
          score={
            reviewDetailInfo!.stars0 +
              reviewDetailInfo!.stars1 +
              reviewDetailInfo!.stars2 +
              reviewDetailInfo!.stars3 || 0
          }
          content={reviewDetailInfo!.content}
          productImage={reviewDetailInfo!.product.list_image}
        />
      )}
    </div>
  );
}
export default MyReviewTab;
