import { INoticeType } from '../../pages/Notice/Notice';
import styles from './NoticeFrame.module.scss';
function NoticeFrame(props: { notice: INoticeType }) {
  return (
    <div className={styles.container}>
      <span className={styles.title}>{props.notice.title}</span>
      <span className={styles.date}>
        {props.notice.start_at.substring(0, 10).replace(/-/gi, '.')}
      </span>
    </div>
  );
}
export default NoticeFrame;
