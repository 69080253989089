import Header from '../../components/Header/Header';
import styles from './EventDetail.module.scss';
import arrow from '../../assets/rightArrow.png';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosClient from '../../libs/axiosClient';
import { useQuery } from 'react-query';
import qs from 'qs';
import { loginConfirm } from '../../common/loginConfirm';
interface IEventDetailType {
  b_content: string;
  b_edate: string;
  b_img1: string;
  b_sdate: string;
  b_title: string;
  b_uid: number;
}
function EventDetail() {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const { data: event } = useQuery<IEventDetailType>(
    ['event', eventId],
    () =>
      axiosClient.get(`/data/event/detail?event_id=${eventId}`).then((res) => {
        if (!Object.keys(res.data.data).length) return undefined;
        else return res.data.data;
      }),
    {
      staleTime: 60000 * 60 * 24, //1일 캐싱
    },
  );

  function generateThankCoupon(code: number) {
    if (!loginConfirm()) return;
    axiosClient
      .post(
        '/func/coupon/create',
        qs.stringify({
          coupon_code: code,
        }),
      )
      .then((res) => {
        if (res.data.result == 'Fail') alert(res.data.data);
        else if (res.data.result == 'OK')
          alert('쿠폰이 정상적으로 발급되었습니다.');
      });
  }
  function generateAllThankCoupon() {
    if (!loginConfirm()) return;
    let formData = new FormData();
    formData.append('coupon_array', String(13));
    formData.append('coupon_array', String(14));
    formData.append('coupon_array', String(15));
    axiosClient.post('/func/coupon/create-pack', formData).then((res) => {
      if (res.data.result == 'Fail') alert(res.data.data);
      else if (res.data.result == 'OK') {
        if (res.data.data.includes('True'))
          alert('쿠폰이 정상적으로 발급되었습니다.');
        else alert('이미 발급받은 쿠폰입니다.');
      }
    });
  }
  useEffect(() => {
    let doc1 = document.querySelectorAll('.thankCoupon1');
    let doc2 = document.querySelectorAll('.thankCoupon2');
    let doc3 = document.querySelectorAll('.thankCoupon3');
    let doc4 = document.querySelectorAll('.thankCoupon4');
    doc1.forEach((document) => {
      document.addEventListener('click', () => {
        generateThankCoupon(15);
      });
    });
    doc2.forEach((document) => {
      document.addEventListener('click', () => {
        generateThankCoupon(14);
      });
    });
    doc3.forEach((document) => {
      document.addEventListener('click', () => {
        generateThankCoupon(13);
      });
    });
    doc4.forEach((document) => {
      document.addEventListener('click', () => {
        generateAllThankCoupon();
      });
    });
    return () => {
      doc1.forEach((document) => {
        document.removeEventListener('click', () => {
          generateThankCoupon(15);
        });
      });
      doc2.forEach((document) => {
        document.removeEventListener('click', () => {
          generateThankCoupon(14);
        });
      });
      doc3.forEach((document) => {
        document.removeEventListener('click', () => {
          generateThankCoupon(13);
        });
      });
      doc4.forEach((document) => {
        document.removeEventListener('click', () => {
          generateAllThankCoupon();
        });
      });
    };
  }, [event]);
  return (
    <>
      <Header />
      <main className={styles.container}>
        <header>
          <div className={styles.backBtn} onClick={() => navigate(-1)}>
            <img src={arrow} alt="뒤로가기" />
          </div>
          <h4>EVENT</h4>
        </header>
        {event && (
          <>
            <h2 className={styles.eventTitle}>
              {(() => {
                let isProgressing = false;
                let date = new Date().getTime();
                let startDate = new Date(event.b_sdate).getTime();
                let endDate = new Date(event.b_edate).getTime();
                if (startDate < date && date < endDate) isProgressing = true;
                return (
                  <div
                    className={`${styles.isProgressing} ${
                      !isProgressing ? styles.isOff : ''
                    }`}
                  >
                    {isProgressing ? '진행중' : '종료'}
                  </div>
                );
              })()}
              <span>{event.b_title}</span>
            </h2>
            <div className={styles.eventInfo}>
              <span>ONDOC</span>
              <span>{event.b_sdate.replace(/-/g, '.')}</span>
            </div>
            <div
              className={styles.contentWrap}
              dangerouslySetInnerHTML={{
                __html: event.b_content.replace(/\\/g, ''),
              }}
            ></div>
          </>
        )}
      </main>
    </>
  );
}
export default EventDetail;
