import styles from './FaqFrame.module.scss';
import bottomArrow from '../../assets/bottomArrow.png';
import { useState } from 'react';
import { IFaqType } from '../../pages/Faq/Faq';
function FaqFrame(props: { faq: IFaqType }) {
  const [answerOpen, setAnswerOpen] = useState(false);
  return (
    <div>
      <div
        className={`${styles.askWrap} ${answerOpen ? styles.open : ''}`}
        onClick={() => setAnswerOpen((prev) => !prev)}
      >
        <div className={styles.qMark}>Q</div>
        <p>{props.faq.title}</p>
        <img src={bottomArrow} alt="bottomArrow" />
      </div>
      {answerOpen && (
        <div className={styles.answerWrap}>
          <div className={styles.aMark}>A</div>
          <p dangerouslySetInnerHTML={{ __html: props.faq.content }}></p>
        </div>
      )}
    </div>
  );
}
export default FaqFrame;
