import { useState } from 'react';
import Header from '../../components/Header/Header';
import styles from './WeightDateChart.module.scss';
import { useNavigate } from 'react-router-dom';
import DateSelectModal from '../../components/DateSelectModal/DateSelectModal';
import DateSelectModalSecond from '../../components/DateSelectModalSecond/DateSelectModalSecond';
import Footer from '../../components/Footer/Footer';
import { isDesktop, isMobile } from 'react-device-detect';

function WeightDateChart() {
  const navigate = useNavigate();
  const [dateSelectOpen, setDateSelectOpen] = useState(false);
  const [dateSecondSelectOpen, setSecondDateSelectOpen] = useState(false);
  const [clickText, setClickText] = useState<String>('');
  const [clickTextSecond, setClickTextSecond] = useState<String>('');
  const [clickYear, setClickYear] = useState<Number>();
  const date = new Date();
  const yeaderDate = date.getFullYear();

  return (
    <>
      <Header />
      {isMobile && (
        <div className={styles.prevWrap} onClick={() => navigate(-1)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
          >
            <path
              d="M13 24L5 15L13 6"
              stroke="#131C31"
              strokeWidth="2"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
            <path d="M5.5 15H24.9998" stroke="#131C31" strokeWidth="2" />
          </svg>
        </div>
      )}
      <main
        className={`${styles.contentWrap} ${isDesktop ? styles.isDesktop : ''}`}
      >
        <div>
          <h3>기간 선택</h3>
        </div>
        <div className={styles.dateInputWrap}>
          {}
          <button onClick={() => setDateSelectOpen(true)}>
            {!clickText && <p>기간을 선택해주세요</p>}

            {clickText && (
              <p style={{ color: '#000' }}>
                {yeaderDate}년 {clickText}월
              </p>
            )}
          </button>
          <span>-</span>
          <button onClick={() => setSecondDateSelectOpen(true)}>
            {!clickTextSecond && <p>기간을 선택해주세요</p>}

            {clickTextSecond && (
              <p style={{ color: '#000' }}>
                {yeaderDate}년 {clickTextSecond}월
              </p>
            )}
          </button>
        </div>
        <button className={styles.inputBtn}>선택완료</button>
      </main>
      {/* {dateSelectOpen && <WeightDateModal />} */}
      {dateSelectOpen && (
        <DateSelectModal
          closer={setDateSelectOpen}
          setClickText={setClickText}
          setClickYear={setClickYear}
        />
      )}
      {dateSecondSelectOpen && (
        <DateSelectModalSecond
          closer={setSecondDateSelectOpen}
          setClickTextSecond={setClickTextSecond}
        />
      )}
      <Footer />
    </>
  );
}

export default WeightDateChart;
