import axiosClient from '../../libs/axiosClient';
import React, { useState, useEffect } from 'react';
import { gapi } from 'gapi-script';
const CLIENT_ID =
  '720911295861-2d5un8bvg8ghkqgs6lo4qfghfudo6mjq.apps.googleusercontent.com';
const DISCOVERY_DOCS = [
  'https://analyticsdata.googleapis.com/$discovery/rest?version=v1beta',
];
const SCOPES =
  'https://www.googleapis.com/auth/analytics https://www.googleapis.com/auth/analytics.readonly';
const PROPERTY_ID = '355215286';
function Admin() {
  const [data, setData] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);

  // 마운트 될 때 로그인 처리하도록 선언
  useEffect(() => {
    gapi.load('client:auth2', initializeGapi);
  }, []);

  const initializeGapi = () => {
    gapi.client
      .init({
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })
      .then(() => {
        setLoggedIn(gapi.auth2.getAuthInstance().isSignedIn.get());
      })
      .catch((error: any) => {
        console.error('gapi client 초기화 실패:', error);
      });
  };
  // login
  const handleLogin = () => {
    gapi.auth2
      .getAuthInstance()
      .signIn()
      .then(() => {
        setLoggedIn(true);
      })
      .catch((error: any) => {
        console.error('Google 인증 실패: ', error);
      });
  };
  useEffect(() => {
    if (!loggedIn) return;
    handleFetchData();
  }, [loggedIn]);
  // GA4 데이터 가져오기
  const handleFetchData = () => {
    gapi.client.analyticsdata.properties
      .batchRunReports({
        property: `properties/${PROPERTY_ID}`,
        resource: {
          requests: [
            // {
            //   dimensions: [
            //     {
            //       name: 'date',
            //     },
            //   ],
            //   metrics: [
            //     {
            //       name: 'activeUsers',
            //     },
            //     {
            //       name: 'sessions',
            //     },
            //   ],
            //   dateRanges: [
            //     {
            //       startDate: '2023-08-16',
            //       endDate: 'today',
            //     },
            //   ],
            // },
            {
              // dimensions: [
              //   {
              //     name: 'eventCategory',
              //   },
              // ],
              metrics: [{ name: 'eventCount' }],
              dimensionFilter: {
                filter: {
                  fieldName: 'eventName',
                  stringFilter: {
                    value: 'purchase_attempt',
                  },
                },
              },
              dateRanges: [
                {
                  startDate: '2023-08-16',
                  endDate: 'today',
                },
              ],
            },
          ],
        },
      })
      .then((response: any) => {
        const { rows } = response.result.reports[0];
        console.log(rows);
        // const transformedData = rows.map((row: any) => ({
        //   date: row.dimensionValues[0].value,
        //   activeUsers: parseInt(row.metricValues[0].value, 10),
        //   sessions: parseInt(row.metricValues[1].value, 10),
        // }));
        // setData(transformedData);
      })
      .catch((error: any) => {
        console.error('fetchData 에러 발생: ', error);
      });
  };

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <h1>Google Analytics Data API Example</h1>
      <div>
        {!loggedIn && <button onClick={handleLogin}>Login with Google</button>}
        {loggedIn && (
          <div>
            {/* <button
              style={{ marginTop: '10px', marginRight: '10px' }}
              onClick={handleFetchData}
            >
              Fetch Data
            </button> */}
          </div>
        )}
      </div>
    </div>
  );
}
export default Admin;
