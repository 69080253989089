import styles from './ReviewDetailModal.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SwiperCore from 'swiper';
import { useEffect, useState } from 'react';
import leftSwipeBtn from '../../assets/leftSwipeBtn.png';
import rightSwipeBtn from '../../assets/rightSwipeBtn.png';
import photoReview02 from '../../assets/imsi/imsiPhotoReview02.png';
import photoReview03 from '../../assets/imsi/imsiPhotoReview03.png';
import star from '../../assets/star.png';
import emptyStar from '../../assets/emptyStar.png';

interface IPropsType {
  closer: React.Dispatch<React.SetStateAction<boolean>>;
  imageList: {
    created_at: string;
    id: number;
    imagable_id: number;
    imagable_type: string;
    image: string;
    updated_at: null | string;
  }[];
  userId: string;
  age: number;
  gender: string;
  weight: number;
  score: number;
  content: string;
  productImage: string;
}
function ReviewDetailModal(props: IPropsType) {
  const [swiper, setSwiper] = useState<SwiperCore>();
  const [activeIdx, setActiveIdx] = useState(0);
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  return (
    <div className={styles.reviewDetailModal}>
      <div className={styles.reviewDetailContent}>
        <span className={styles.closeBtn} onClick={() => props.closer(false)}>
          닫기
        </span>
        <div className={styles.imageSliderWrap}>
          {props.imageList.length > 1 && (
            <>
              {activeIdx !== 0 && (
                <img
                  src={leftSwipeBtn}
                  alt="arrow"
                  className={styles.swipeBtn}
                  onClick={() => {
                    swiper?.slidePrev();
                  }}
                />
              )}
              {activeIdx < props.imageList.length - 1 && (
                <img
                  src={rightSwipeBtn}
                  alt="arrow"
                  className={`${styles.swipeBtn} ${styles.right}`}
                  onClick={() => {
                    swiper?.slideNext();
                  }}
                />
              )}
            </>
          )}
          <Swiper
            onSwiper={setSwiper}
            onSlideChange={(e) => setActiveIdx(e.activeIndex)}
          >
            {props.imageList.length > 0 ? (
              props.imageList.map((image) => (
                <SwiperSlide key={image.id}>
                  <img
                    src={image.image + '?w=800'}
                    alt="reviewImage"
                    className={styles.reviewImage}
                  />
                </SwiperSlide>
              ))
            ) : (
              <SwiperSlide>
                <img
                  src={props.productImage + '?w=800'}
                  alt="reviewImage"
                  className={styles.reviewImage}
                />
              </SwiperSlide>
            )}
          </Swiper>
        </div>
        <div className={styles.reviewInfoWrap}>
          <div className={styles.userInfoWrap}>
            <span className={styles.userId}>{props.userId}</span>
            <span className={styles.userInfo}>
              {props.age}대 /{' '}
              {props.gender == 'F'
                ? '여성'
                : props.gender == 'M'
                ? '남성'
                : '선택안함'}{' '}
              {props.weight > 0 && `/ 감량체중 -${props.weight}kg`}
            </span>
          </div>
          <div className={styles.starWrap}>
            {(() => {
              let list: boolean[] = [];
              for (let i = 1; i <= 5; i++) {
                if (i <= Math.round(props.score / 4 / 20)) list.push(true);
                else list.push(false);
              }
              <img src={star} alt="star" />;
              return list.map((isTrue, idx) => {
                if (isTrue) return <img src={star} alt="star" key={idx} />;
                else return <img src={emptyStar} alt="star" key={idx} />;
              });
            })()}
          </div>
          <p className={styles.content}>{props.content}</p>
        </div>
      </div>
    </div>
  );
}
export default ReviewDetailModal;
