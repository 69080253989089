import { useEffect, useState } from 'react';
import { useQueryClient, useQuery } from 'react-query';
import axiosClient from '../../libs/axiosClient';
import { IReviewYetType } from '../../pages/MyReview/MyReview';
import WriteReviewModal from '../WriteReviewModal/WriteReviewModal';
import leftArrow from '../../assets/leftArrow02.png';
import styles from './ReviewYetTab.module.scss';
import PostReviewModal from '../ReviewModals/PostReviewModal/PostReviewModal';
function ReviewYetTab() {
  const [writableReviews, setWritableReviews] = useState<IReviewYetType[]>();
  const [selectedReviewYet, setSelectedReviewYet] = useState<IReviewYetType>();
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [pagination, setPagination] = useState(1);
  const [paginationNumber, setPaginationNumber] = useState<number[]>([]);
  const [reviewCount, setReviewCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    let list: number[] = [];
    let startPage = parseInt(String(pagination / 5)) * 5 + 1;
    if (!(pagination % 5)) startPage -= 5;
    let endPage =
      startPage + 4 < reviewCount / 3 + (reviewCount % 3 !== 0 ? 1 : 0)
        ? startPage + 4
        : reviewCount / 3 + (reviewCount % 3 !== 0 ? 1 : 0);
    for (let i = startPage; i <= endPage; i++) list.push(i);
    if (!list.length) list.push(1);
    setPaginationNumber([...list]);
  }, [reviewCount, pagination]);
  function getReviewYet() {
    axiosClient
      .get(
        `/data/product_talk/list/review_yet?offset=${
          (pagination - 1) * 3
        }&limit=3&talk_type=상품평가`,
      )
      .then((res) => {
        setWritableReviews(res.data.data);
        setReviewCount(res.data.total_count);
        setIsLoading(false);
      });
  }
  useEffect(() => {
    setIsLoading(true);
    getReviewYet();
  }, [pagination]);
  function handlePaginationDown() {
    if (pagination == 1) return;
    setPagination((prev) => prev - 1);
  }
  function handlePaginationUp() {
    if (pagination >= reviewCount / 3) return;
    setPagination((prev) => prev + 1);
  }
  return (
    <>
      <div>
        <ul className={styles.orderList}>
          {!isLoading &&
            writableReviews &&
            writableReviews.map((order) => (
              <li key={order.order_product_id}>
                <div className={styles.orderBox}>
                  <div className={styles.left}>
                    <div className={styles.productImageWrap}>
                      <img src={order.list_image} alt="thumbnail" />
                    </div>
                    <div className={styles.productInfoWrap}>
                      <span className={styles.productName}>
                        [ {order.product_name} ]
                      </span>
                      <div className={styles.optionInfo}>
                        <span>옵션</span>
                        <span>
                          {order.op_option1
                            ? order.op_option1
                            : order.product_name}
                          {order.op_option2 && ' / ' + order.op_option2}
                          {order.op_option3 && ' / ' + order.op_option3}
                        </span>
                      </div>
                      <div className={styles.pointInfo}>
                        <span>포토후기 5,000P</span>
                        {/* <span>일반후기 100P</span> */}
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      setReviewModalOpen(true);
                      setSelectedReviewYet(order);
                    }}
                  >
                    후기작성
                  </button>
                </div>
              </li>
            ))}
        </ul>
        <div className={styles.paginationWrap}>
          <img src={leftArrow} alt="leftArrow" onClick={handlePaginationDown} />
          <ul>
            {paginationNumber.map((number) => (
              <li
                key={number}
                className={number == pagination ? styles.hit : ''}
                onClick={() => setPagination(number)}
              >
                {number}
              </li>
            ))}
          </ul>
          <img
            src={leftArrow}
            alt="rightArrow"
            style={{ transform: 'rotate(180deg)' }}
            onClick={handlePaginationUp}
          />
        </div>
      </div>
      {reviewModalOpen && (
        <PostReviewModal
          closer={setReviewModalOpen}
          reviewYet={selectedReviewYet!}
          getReviewYet={getReviewYet}
        />
      )}
    </>
  );
}
export default ReviewYetTab;
