import styles from './DoctorFrame.module.scss';
import { useEffect, useState } from 'react';
import leesoyoungMobile from '../../assets/doctors/leesoyoungMobile.png';
import youjiyouMobile from '../../assets/doctors/youjiyouMobile.png';
import kimmiriMobile from '../../assets/doctors/kimmiriMobile.png';
import doctorDrop from '../../assets/home/doctorDrop.png';

export interface IDoctorType {
  name: string;
  ename: string;
  content: string;
}

function DoctorFrame(props: { doctor: IDoctorType }) {
  const [isOpen, setIsOpen] = useState(false);
  const [doctorImg, setDoctorImg] = useState('');
  useEffect(() => {
    if (props.doctor.name === '이소영') setDoctorImg(leesoyoungMobile);
    if (props.doctor.name === '유지유') setDoctorImg(youjiyouMobile);
    if (props.doctor.name === '김미리') setDoctorImg(kimmiriMobile);
  }, []);
  return (
    <div className={styles.doctorSliderWrap}>
      <div
        className={styles.doctorNameWrap}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div className={styles.doctorNameLeft}>
          <img src={doctorImg} alt="doctor" />
          <div className={styles.doctorName}>
            <p>{props.doctor.name} 한의사</p>
            <p>{props.doctor.ename}</p>
          </div>
        </div>
        <img
          src={doctorDrop}
          alt="drop"
          style={{ transform: isOpen ? 'rotate(180deg)' : '' }}
        />
      </div>
      {isOpen && (
        <div className={styles.doctorInfoWrap}>
          <p>약력</p>
          <div
            className={`${styles.answerWrap} ${isOpen ? styles.isOpen : ''}`}
            dangerouslySetInnerHTML={{ __html: props.doctor.content }}
          ></div>
        </div>
      )}
    </div>
  );
}
export default DoctorFrame;
