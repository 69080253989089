import styles from './ADBanner.module.scss';
import product from '../../assets/adBanner/product.png';
import pc from '../../assets/adBanner/pc.png';
import mobile from '../../assets/adBanner/mobile.png';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isDesktop } from 'react-device-detect';
import { checkRequired } from '../../common/checkRequired';
interface IPropsType {
  endDate: string;
  productId: number;
  price: number;
  discountedPrice: number;
}
function ADBanner({ endDate, productId, price, discountedPrice }: IPropsType) {
  const navigate = useNavigate();
  const [idx, setIdx] = useState(0);
  useEffect(() => {
    if (!checkRequired('2023-07-17T00:00:00')) return;
    setTimeout(() => {
      if (idx == 0) setIdx(1);
      else if (idx == 1) setIdx(0);
    }, 3000);
  }, [idx]);
  return (
    <>
      <div className={styles.heighter}></div>
      {idx == 0 && (
        <div
          className={styles.container}
          onClick={() =>
            (window.location.href = `https://ondoc.co.kr/productDetail/${productId}`)
          }
        >
          <div className={styles.productImageWrap}>
            <img src={product} alt="상품썸네일" />
          </div>
          <h1 className={styles.productName}>청담보감 다이어트 타블렛</h1>
          <div className={styles.priceWrap}>
            <div className={styles.price}>
              <span>정상가</span>
              <span> : </span>
              <span>{'229,000'}원</span>
            </div>
            <div className={styles.discountedPrice}>
              <span>할인가</span>
              <span> : </span>
              <span>{discountedPrice.toLocaleString()}원</span>
            </div>
          </div>
        </div>
      )}
      {idx == 1 && (
        <div
          className={styles.couponEvent}
          onClick={() => navigate('/event/20')}
        >
          <img
            src={isDesktop ? pc : mobile}
            style={{ maxWidth: isDesktop ? 1920 : 375 }}
            alt="쿠폰이벤트"
          />
        </div>
      )}
    </>
  );
}
export default ADBanner;
