export function getPayMethodLabel(payMethod: string) {
  switch (payMethod) {
    case 'card':
      return '신용카드';
    case 'iche':
      return '계좌이체';
    case 'point':
      return '포인트';
    case 'hpp':
      return '휴대폰결제';
    case 'payco':
      return '페이코';
    case 'payple':
      return '카드';
    case 'naverpay':
      return '네이버페이';
    case 'kakaopay':
      return '카카오페이';
    case 'wechat':
      return '위챗';
    case 'applepay':
      return '애플페이';
    case 'smilepay':
      return '스마일페이';
    case 'paypal':
      return '페이팔';
    default:
      return payMethod;
  }
}
