import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { loginConfirm } from '../../common/loginConfirm';
import styles from './ReferralNavigate.module.scss';
function ReferralNavigate() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <ul className={styles.navigationWrap}>
      <li
        onClick={() => {
          navigate('/referral');
        }}
        className={location.pathname == '/referral' ? styles.hit : ''}
      >
        추천 목록
      </li>
      <li
        onClick={() => {
          navigate('/referral-point');
        }}
        className={location.pathname == '/referral-point' ? styles.hit : ''}
      >
        추천 적립금
      </li>
    </ul>
  );
}
export default ReferralNavigate;
