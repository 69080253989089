const SETFALSE = 'SETFALSE';
const SETTRUE = 'SETTRUE';
export const setFalseInitialize = () => {
  return {
    type: SETFALSE,
  };
};
export const setTrueInitialize = () => {
  return {
    type: SETTRUE,
  };
};
export const gaInitialize = (
  state: boolean = true,
  action: { type: string },
) => {
  if (action.type == SETFALSE) return false;
  else return true;
};
