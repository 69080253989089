import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { loginConfirm, loginConfirmRurl } from '../../common/loginConfirm';
import styles from './MyPageNavigate.module.scss';
function MyPageNavigate() {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <ul className={styles.navigationWrap}>
      <li
        onClick={() => {
          navigate('/basket');
        }}
        className={location.pathname == '/basket' ? styles.hit : ''}
      >
        장바구니
      </li>
      <li
        onClick={() => {
          if (!loginConfirmRurl('/point')) return;
          navigate('/point');
        }}
        className={location.pathname == '/point' ? styles.hit : ''}
      >
        적립금
      </li>
      <li
        onClick={() => {
          if (!loginConfirmRurl('/pick')) return;
          navigate('/pick');
        }}
        className={location.pathname == '/pick' ? styles.hit : ''}
      >
        찜한상품
      </li>
      <li
        onClick={() => {
          if (!loginConfirmRurl('/coupon')) return;
          navigate('/coupon');
        }}
        className={location.pathname == '/coupon' ? styles.hit : ''}
      >
        쿠폰
      </li>
      <li
        onClick={() => {
          if (!loginConfirmRurl('/myReview')) return;
          navigate('/myReview');
        }}
        className={location.pathname == '/myReview' ? styles.hit : ''}
      >
        나의 후기
      </li>
      <li
        onClick={() => {
          if (!loginConfirmRurl('/updateProfile')) return;
          navigate('/updateProfile');
        }}
        className={location.pathname == '/updateProfile' ? styles.hit : ''}
      >
        회원정보수정
      </li>
      <li
        onClick={() => {
          if (!loginConfirmRurl('/myCard')) return;
          navigate('/myCard');
        }}
        className={location.pathname == '/myCard' ? styles.hit : ''}
      >
        간편카드등록
      </li>
      <li
        onClick={() => {
          if (!loginConfirmRurl('/referral')) return;
          navigate('/referral');
        }}
        className={location.pathname == '/referral' ? styles.hit : ''}
      >
        추천인
      </li>
    </ul>
  );
}
export default MyPageNavigate;
