import styles from './MainBanner.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SwiperCore from 'swiper';
import { useEffect, useState } from 'react';
import banner from '../../assets/banner/mainBanner.jpg';
import banner2 from '../../assets/banner/mainBanner02.jpg';
import banner3 from '../../assets/banner/mainBanner03.jpg';
import banner4 from '../../assets/banner/mainBanner04.jpg';
import banner5 from '../../assets/banner/mainBanner05.jpg';
import banner6 from '../../assets/banner/mainBanner06.jpg';
import { useNavigate } from 'react-router-dom';
import { checkRequired } from '../../common/checkRequired';
const imsiSliderList = [banner];
export interface IBannerType {
  image: string;
  url?: string;
}
function MainBanner() {
  const [activeIdx, setActiveIdx] = useState(0);
  const [swiper, setSwiper] = useState<SwiperCore>();
  const navigate = useNavigate();
  const [slideList, setSlideList] = useState<IBannerType[]>([]);
  useEffect(() => {
    setSlideList(() => {
      let list: IBannerType[] = [
        { image: banner6, url: 'productDetail/116' },
        { image: banner3, url: 'event/22' },
        { image: banner5, url: 'event/24' },
        { image: banner4, url: 'event/23' },
      ];

      return [...list];
    });
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.bannerSliderWrap}>
        <Swiper
          onSwiper={setSwiper}
          slidesPerView={1}
          spaceBetween={0}
          className={styles.bannerSlider}
          onSlideChange={(e) => setActiveIdx(e.activeIndex)}
        >
          {slideList.map((slide, idx) => (
            <SwiperSlide
              key={idx}
              onClick={() => {
                if (slide.url) navigate(slide.url);
              }}
            >
              <img
                src={slide.image}
                alt="banner"
                style={{ cursor: slide.url ? 'pointer' : 'unset' }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <ul className={styles.paginationWrap}>
          {slideList.map((banner, idx) => (
            <li
              key={idx}
              onClick={() => swiper?.slideTo(idx, 200)}
              className={idx == activeIdx ? styles.hit : ''}
            ></li>
          ))}
        </ul>
      </div>
    </div>
  );
}
export default MainBanner;
