import { divide } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import axiosClient from '../../libs/axiosClient';
import styles from './FindAccount.module.scss';
import inputCancel from '../../assets/inputCancel.png';
import selectOpen from '../../assets/selectOpen.png';
function FindAccount() {
  const location = useLocation();
  const [tab, setTab] = useState(0);
  //아이디찾기 관련 상태
  const [name, setName] = useState('');
  const [tel, setTel] = useState('');
  const [idResult, setIdResult] = useState('');
  const [emailResult, setEmailResult] = useState('');
  const [typeResult, setTypeResult] = useState('');
  function findId() {
    axiosClient
      .put('/func/user/id/find', {
        name: name,
        phone: tel,
      })
      .then((res) => {
        setIdResult(res.data.data.user_id);
        setEmailResult(res.data.data.user_email);
        setTypeResult(res.data.data.type);
      })
      .catch((err) => alert('입력한 정보를 다시 확인해주세요.'));
  }
  //아이디찾기 관련 상태

  //비밀번호찾기 관련 상태
  const [id, setId] = useState('');
  const [email1, setEmail1] = useState('');
  const [email2, setEmail2] = useState('');
  const [emailDirectly, setEmailDirectly] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  function findPw() {
    axiosClient
      .put('/func/user/password/email_find', {
        id: id,
        email: email1 + '@' + email2,
      })
      .then((res) => {
        if (res.data.data.status_code == 200) setPasswordChanged(true);
      })
      .catch((err) => alert(err.response.data.detail));
  }
  //비밀번호찾기 관련 상태

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('tab') == '1') setTab(1);
  }, []);
  return (
    <div>
      <Header />
      <div className={styles.contentWrap}>
        <ul className={styles.tabs}>
          <li className={tab == 0 ? styles.hit : ''} onClick={() => setTab(0)}>
            아이디 찾기
          </li>
          <li className={tab == 1 ? styles.hit : ''} onClick={() => setTab(1)}>
            비밀번호 찾기
          </li>
        </ul>
        {tab == 0 ? (
          <div className={styles.idFindForm}>
            <div className={styles.inputWrap}>
              <span>이름</span>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
              />
            </div>
            <div className={styles.inputWrap}>
              <span>가입시 연락처</span>
              <div className={styles.telInputWrap}>
                <input
                  type="text"
                  value={tel}
                  onChange={(e) => {
                    let value = e.currentTarget.value;
                    if (isNaN(Number(value)) && value !== '+') return;
                    setTel(value);
                  }}
                  placeholder=""
                />
              </div>
            </div>
            <div className={styles.submitBtn} onClick={findId}>
              확인
            </div>
            {idResult && (
              <p className={styles.resultMessage}>
                고객님께서 조회하신 아이디는
                <br />
                {typeResult == 'KAKAO'
                  ? `${emailResult}(카카오 간편가입)`
                  : idResult}{' '}
                입니다.
              </p>
            )}
          </div>
        ) : (
          <div className={styles.passwordFindForm}>
            <div className={styles.inputWrap}>
              <span>아이디</span>
              <input
                type="text"
                value={id}
                onChange={(e) => setId(e.currentTarget.value)}
              />
            </div>
            <div className={styles.inputWrap}>
              <span>이메일주소</span>
              <div className={styles.emailInputWrap}>
                <input
                  type="text"
                  value={email1}
                  onChange={(e) => {
                    setEmail1(e.currentTarget.value);
                  }}
                />
                <span>@</span>
                <div className={styles.customSelect}>
                  <input
                    type="text"
                    value={email2}
                    placeholder="직접입력"
                    onChange={(e) => {
                      setEmail2(e.currentTarget.value);
                    }}
                  />
                  <select
                    name=""
                    id=""
                    onChange={(e) => {
                      if (e.currentTarget.value == 'directly') {
                        setEmailDirectly(true);
                        setEmail2('');
                      } else setEmail2(e.currentTarget.value);
                    }}
                    style={{ display: emailDirectly ? 'none' : '' }}
                  >
                    <option disabled selected>
                      선택해주세요.
                    </option>
                    <option value="naver.com">naver.com</option>
                    <option value="hanmail.net">hanmail.net</option>
                    <option value="daum.net">daum.net</option>
                    <option value="gmail.com">gmail.com</option>
                    <option value="nate.com">nate.com</option>
                    <option value="hotmail.com">hotmail.com</option>
                    <option value="outlook.com">outlook.com</option>
                    <option value="icloud.com">icloud.com</option>
                    <option value="directly">직접입력</option>
                  </select>
                  <img
                    src={selectOpen}
                    alt="selectOpen"
                    style={{
                      display: emailDirectly ? 'none' : '',
                      pointerEvents: 'none',
                    }}
                  />
                  <img
                    src={inputCancel}
                    alt="inputCancel"
                    style={{ display: !emailDirectly ? 'none' : '' }}
                    onClick={() => setEmailDirectly(false)}
                  />
                </div>
              </div>
            </div>
            <div className={styles.submitBtn} onClick={findPw}>
              확인
            </div>
            {passwordChanged && (
              <p className={styles.resultMessage}>
                입력하신 이메일 주소로
                <br />
                임시 비밀번호를 발송했습니다.
              </p>
            )}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}
export default FindAccount;
