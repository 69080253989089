import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './GetGuideURL.module.scss';
import guide1 from '../../assets/getGuideURL/sellerGuide1.png';
import guide2 from '../../assets/getGuideURL/sellerGuide2.png';
import guide3 from '../../assets/getGuideURL/sellerGuide3.png';
function GetGuideURL() {
  const [input, setInput] = useState('');
  const [newUrl, setNewUrl] = useState('');
  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let productId = input.split('productDetail/')[1];
    if (!productId || !input.includes('productDetail')) {
      alert('URL을 잘못 입력하였습니다. 다시 입력해주세요.');
      return;
    } else setNewUrl(`${window.location.origin}/inAppGuide/${productId}`);
  }
  useEffect(() => {
    setNewUrl('');
  }, [input]);
  // console.log(newUrl);
  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={(e) => handleSubmit(e)}>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.currentTarget.value)}
          // placeholder="상품 상세 페이지에서 URL을 복사한 후 이곳에 입력해주세요."
        />
        <button>입력</button>
      </form>
      <p>상품 상세 페이지에서 URL을 복사한 후 이곳에 입력해주세요.</p>
      <span className={styles.newUrl}>{newUrl}</span>
      <ul>
        <li>
          <span>1.</span>
          <img src={guide1} />
        </li>
        <li>
          <span>2.</span>

          <img src={guide2} />
        </li>
        <li>
          <span>3.</span>

          <img src={guide3} />
        </li>
      </ul>
    </div>
  );
}
export default GetGuideURL;
