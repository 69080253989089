import axios from 'axios';
import { loginCheck, logout, refreshTokenCheck } from '../auth/auth';
const axiosClient = axios.create({
  // baseURL: '',
  baseURL: 'https://api.ondoc.co.kr',
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': 'https://api.ondoc.co.kr',
    'Access-Control-Allow-Credentials': true,
    'ngrok-skip-browser-warning': '69420',
  },
});

let isTokenRefreshingReq = false;
let refreshSubscribersReq: any[] = [];

const onTokenRefreshedReq = (accessToken: string) => {
  refreshSubscribersReq.map((callback) => {
    callback(accessToken);
  });
};

const addRefreshSubscriberReq = (callback: any) => {
  refreshSubscribersReq.push(callback);
};

axiosClient.interceptors.request.use(
  async function (config) {
    if (loginCheck()) {
      //유효한 토큰 보유시
      config.headers!.Authorization = `Bearer ${localStorage.getItem('token')}`;
      return config;
    } else {
      let refreshToken = localStorage.getItem('refresh-token');
      if (!refreshTokenCheck()) {
        logout();
        return config; //만료된 퇴큰 보유, 리프레시 토큰 만료 혹은 미보유 시 Authorization세팅하지 않고 그대로 요청 진행, 이후 403발생 및 response 인터셉터로 인해 로그아웃 및 홈 리다이렉트
      } else {
        //유효한 리프레시 토큰 보유시
        const retryOriginalRequest = new Promise((resolve) => {
          addRefreshSubscriberReq((accessToken: any) => {
            if (accessToken)
              config.headers!.Authorization = 'Bearer ' + accessToken;
            else delete config.headers!.Authorization;
            resolve(config);
          });
        });
        if (!isTokenRefreshingReq) {
          isTokenRefreshingReq = true;
          const res = await axios
            .post(
              // `/func/user/refresh-token`,
              `https://api.ondoc.co.kr/func/user/refresh-token`, // token refresh api
              {
                refresh_token: `Bearer ${refreshToken}`,
              },
            )
            .catch((err) => {
              return {
                data: {
                  access_token: null,
                  refresh_token: null,
                  access_token_expire: null,
                  refresh_token_expire: null,
                },
              };
            });
          if (localStorage.getItem('mobileApp')) {
            window.flutter_inappwebview
              .callHandler('syncAccessToken')
              .then((res: any) => {
                return res;
              })
              .catch((err: any) => {
                return err;
              });
          }
          // 새로운 토큰 저장
          const {
            access_token,
            refresh_token,
            access_token_expire,
            refresh_token_expire,
          } = res.data;
          localStorage.setItem('token', access_token);
          localStorage.setItem('refresh-token', refresh_token);
          localStorage.setItem('token-expire', access_token_expire);
          localStorage.setItem('refresh-token-expire', refresh_token_expire);
          isTokenRefreshingReq = false;

          if (access_token)
            axiosClient.defaults.headers.common.Authorization = `Bearer ${access_token}`;
          onTokenRefreshedReq(access_token);
        }
        return retryOriginalRequest;
      }
    }
  },
  function (error) {
    // 요청 오류가 있는 작업 수행
    return Promise.reject(error);
  },
);

let isTokenRefreshing = false;
let refreshSubscribers: any[] = [];

// refresh토큰으로 access토큰을 재발급 받은 후에 호출되는 함수로,
// access 토큰을 인자로 받아 refreshSubscribers에 저장된 함수들에게
// access 토큰을 인자로 넘겨서 실행시킴
const onTokenRefreshed = (accessToken: any) => {
  refreshSubscribers.map((callback) => {
    //여기서 callback이란 137번째에 있는 함수
    callback(accessToken);
  });
};

// 인자로 넘어온 함수 자체를 refreshSubscribers 배열에 push
const addRefreshSubscriber = (callback: any) => {
  refreshSubscribers.push(callback);
};

//response.use 내부 첫번째 매개변수에는 해당 응답이 성공일 때 실행될 함수, 두번째 매개변수는 해당 응답이 실패일 때 실행될 함수
axiosClient.interceptors.response.use(
  (response) => {
    return response; //응답이 성공이므로 응답 그대로 return
  },
  async (error) => {
    if (!error || !error.response || !error.response.status) {
      return Promise.reject(error); // 네트워크 에러로 인해 response 없을 시 단순 에러 반환
    }
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config; //실패 응답의 경우 원본 요청을 변수에 저장
    if (status === 401) {
      //401에러는 토큰이 만료됐을 때
      // token이 재발급 되는 동안의 요청은 refreshSubscribers에 저장
      const retryOriginalRequest = new Promise((resolve) => {
        addRefreshSubscriber(
          //refreshSubscribers 배열에 아래 함수를 push함, 해당 함수가 바로 실행되는것이 아님
          (accessToken: any) => {
            if (accessToken)
              originalRequest.headers.Authorization = 'Bearer ' + accessToken;
            else delete originalRequest.headers.Authorization;
            resolve(axiosClient(originalRequest));
          },
          //-----
        );
      });
      // 특정 페이지 내에서 3건의 API 요청이 방문과 동시에 발생했다고 했을 때
      // 3건의 요청이 모두 401에러가 발생했다해서 리프레시토큰을 이용해
      // 토큰을 재발급 받는 과정을 3번 할 필욘 없으므로
      // 아래 if문을 사용
      if (!isTokenRefreshing) {
        // 첫 401에러를 응답받은 요청에 대해서만 if문 내부로 들어온 후 isTokenRefreshing을
        // true로 바꿔주므로써 이후 떨어지는 401에러에 대해서는 해당 요청을 refreshSubscribers에 저장만 하고 끝냄
        isTokenRefreshing = true;
        const refreshToken = localStorage.getItem('refresh-token'); //로컬스토리지에서 리프레시토큰 추출
        //리프레시 토큰이 falsy한 값일 경우 로그아웃, 로그인 페이지로 이동시킴
        if (!refreshToken || refreshToken == 'null') {
          logout(); //저장된 모든 인증 토큰 제거하는 함수
          window.location.href = `/login?rurl=${
            window.location.pathname + window.location.search
          }`;
        }
        // 리프레시 토큰을 이용해 토큰 재발급 요청
        const res = await axios
          .post(
            `https://api.ondoc.co.kr/func/user/refresh-token`, // token refresh api
            {
              refresh_token: `Bearer ${refreshToken}`,
            },
          )
          .catch((err) => {
            return {
              data: {
                access_token: null,
                refresh_token: null,
                access_token_expire: null,
                refresh_token_expire: null,
              },
            };
          });
        if (localStorage.getItem('mobileApp')) {
          window.flutter_inappwebview
            .callHandler('syncAccessToken')
            .then((res: any) => {
              return res;
            })
            .catch((err: any) => {
              return err;
            });
        }
        // 새로운 토큰 저장
        const {
          access_token,
          refresh_token,
          access_token_expire,
          refresh_token_expire,
        } = res.data; //재발급 요청을 통해 받은 access토큰, 리프레시 토큰, 각각의 만료기간 정보를 변수에 담아 선언
        //재발급 통해 받은 데이터들을 로컬스토리지에 저장
        localStorage.setItem('token', access_token);
        localStorage.setItem('refresh-token', refresh_token);
        localStorage.setItem('token-expire', access_token_expire);
        localStorage.setItem('refresh-token-expire', refresh_token_expire);
        //토큰 재발급이 완료됐으므로 isTokenRefreshing을 다시 false로 변환
        isTokenRefreshing = false;

        if (access_token)
          //모든 api 요청시 header에 재발급 받은 access토큰이 디폴트로 삽입
          axiosClient.defaults.headers.common.Authorization = `Bearer ${access_token}`;
        else {
          logout();
          window.location.href = `/login?rurl=${
            window.location.pathname + window.location.search
          }`;
        }
        // 새로운 토큰으로 지연되었던 요청 진행
        onTokenRefreshed(access_token);
      }

      return retryOriginalRequest;
    } else if (status === 403) {
      const tokenExpire = localStorage.getItem('token-expire');
      const refreshToken = localStorage.getItem('refresh-token');
      const refreshTokenExpire = localStorage.getItem('refresh-token-expire');

      if (
        tokenExpire &&
        new Date().getTime() >= new Date(tokenExpire).getTime()
      ) {
        if (
          refreshToken &&
          refreshTokenExpire &&
          new Date().getTime() < new Date(refreshTokenExpire).getTime()
        ) {
          const retryOriginalRequest = new Promise((resolve) => {
            addRefreshSubscriber((accessToken: any) => {
              if (accessToken) {
                originalRequest.headers.Authorization = 'Bearer ' + accessToken;
              } else {
                delete originalRequest.headers.Authorization;
              }
              resolve(axiosClient(originalRequest));
            });
          });

          retryOriginalRequest
            .then(() => {
              refreshSubscribers.length = 0;
            })
            .catch((err) => {
              return err;
            });
          if (!isTokenRefreshing) {
            isTokenRefreshing = true;
            const refreshToken = localStorage.getItem('refresh-token'); //
            if (!refreshToken || refreshToken == 'null') {
              logout(); //저장된 모든 인증 토큰 제거하는 함수
              window.location.href = `/login?rurl=${
                window.location.pathname + window.location.search
              }`;
            }
            // 리프레시 토큰을 이용해 토큰 재발급 요청
            const res = await axios
              .post(
                // `/func/user/refresh-token`,
                `https://api.ondoc.co.kr/func/user/refresh-token`, // token refresh api
                {
                  refresh_token: `Bearer ${refreshToken}`,
                },
              )
              .catch((err) => {
                return {
                  data: {
                    access_token: null,
                    refresh_token: null,
                    access_token_expire: null,
                    refresh_token_expire: null,
                  },
                };
              });
            if (localStorage.getItem('mobileApp')) {
              window.flutter_inappwebview
                .callHandler('syncAccessToken')
                .then((res: any) => {
                  return res;
                })
                .catch((err: any) => {
                  return err;
                });
            }
            // 새로운 토큰 저장
            const {
              access_token,
              refresh_token,
              access_token_expire,
              refresh_token_expire,
            } = res.data;
            localStorage.setItem('token', access_token);
            localStorage.setItem('refresh-token', refresh_token);
            localStorage.setItem('token-expire', access_token_expire);
            localStorage.setItem('refresh-token-expire', refresh_token_expire);
            isTokenRefreshing = false;

            if (access_token)
              axiosClient.defaults.headers.common.Authorization = `Bearer ${access_token}`;
            else {
              logout();
              window.location.href = `/login?rurl=${
                window.location.pathname + window.location.search
              }`;
            }
            onTokenRefreshed(access_token);
          }
          return retryOriginalRequest;
        } else {
          logout();
          window.location.href = `/login?rurl=${
            window.location.pathname + window.location.search
          }`;
        }
      } else {
        // alert('로그인 정보가 만료되었습니다.');
        logout();
        window.location.href = `/login?rurl=${
          window.location.pathname + window.location.search
        }`;
      }
      // logout();
      // window.location.href = `/login?rurl=${
      //   window.location.pathname + window.location.search
      // }`;
    }
    return Promise.reject(error);
  },
);

export default axiosClient;
