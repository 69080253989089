import styles from './NormalReviewFrame.module.scss';
import star from '../../assets/star.png';
import emptyStar from '../../assets/emptyStar.png';
import photoReview02 from '../../assets/imsi/imsiPhotoReview02.png';
import photoReview03 from '../../assets/imsi/imsiPhotoReview03.png';
import photoReview04 from '../../assets/imsi/imsiPhotoReview04.png';
import { IReviewDataType } from '../../pages/MyReview/MyReview';
import { useState } from 'react';
import ReviewDetailModal from '../ReviewDetailModal/ReviewDetailModal';

function NormalReviewFrame(props: { review: IReviewDataType }) {
  const [reviewDetailOpen, setReviewDetailOpen] = useState(false);
  return (
    <div className={styles.container}>
      <div className={styles.userInfoWrap}>
        <span className={styles.userId}>{props.review.member_id}</span>
        <span className={styles.userInfo}>
          {props.review.age}대 / {props.review.gender == 'F' ? '여성' : '남성'}
          {props.review.weight > 0 && ` / -${props.review.weight}kg`}
        </span>
      </div>
      <div className={styles.starWrap}>
        {(() => {
          let list: boolean[] = [];
          for (let i = 1; i <= 5; i++) {
            if (
              i <=
              Math.round(
                (props.review.stars0 +
                  props.review.stars1 +
                  props.review.stars2 +
                  props.review.stars3) /
                  4 /
                  20,
              )
            )
              list.push(true);
            else list.push(false);
          }
          <img src={star} alt="star" />;
          return list.map((isTrue, idx) => {
            if (isTrue) return <img src={star} alt="star" key={idx} />;
            else return <img src={emptyStar} alt="star" key={idx} />;
          });
        })()}
      </div>
      <p className={styles.content}>{props.review.content}</p>
      <div className={styles.reviewImageWrap}>
        {props.review.images
          .filter((image, idx) => idx < 3)
          .map((image) => (
            <img
              src={image.image + '?w=570'}
              alt="reviewImage"
              key={image.id}
              onClick={() => setReviewDetailOpen(true)}
            />
          ))}
      </div>
      {reviewDetailOpen && (
        <ReviewDetailModal
          closer={setReviewDetailOpen}
          imageList={props.review.images}
          userId={props.review.member_id}
          age={props.review.age}
          gender={props.review.gender}
          weight={props.review.weight}
          score={
            props.review.stars0 +
            props.review.stars1 +
            props.review.stars2 +
            props.review.stars3
          }
          content={props.review.content}
          productImage={props.review.product.list_image}
        />
      )}
    </div>
  );
}
export default NormalReviewFrame;
